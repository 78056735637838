import {
  notification,
  Button,
  Col,
  Form,
  Row,
  Spin,
  Input,
  Alert,
  Tooltip,
  InputNumber,
  Result,
  Divider,
  Tabs,
  Drawer,
  FormInstance,
  Skeleton,
  DatePicker,
  Badge,
} from "antd";
import Title from "antd/lib/typography/Title";
import { ReactElement, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { ConfiguratorContext } from "../../context";
import { useHistory, useParams } from "react-router-dom";
import { Utils } from "../../util/util";
import CustomerEntry from "../customer_entry";
import { QuoteStatus, Quote, PricingBreakdown, 
  QUOTE_DEFAULTS, User, ApprovalRequest, ChangeLeadTime, 
  dealerEditCustomChassisMaximum, dealerEditBevMaximum, dealerEditIceMaximum, defaultMaxDiscountPercent, Permission, 
  CustomOptionType,
  QuoteReview,
  DAYS_IN_WEEK,
  SalesTeam,
  ApproverRole,
  MotorFamily,
  CommentTopic,
  QuoteComment,
  CommentStats,
  DEFAULT_THROTTLE,
  } from "../../api/models"
import PricingView from "./PricingView";
import _, {debounce, throttle} from "lodash";
import { useIntl } from "react-intl";
import AssemblySectionMenu from "./AssemblySectionMenu";
import { AsyncState, useAsyncState } from "../../hook/useAsyncState";
import { WarningOutlined, DollarCircleFilled, CommentOutlined, MenuOutlined, FileOutlined } from "@ant-design/icons";
import SelectPricingSnapshot from "../SelectPricingSnapshot";
import useCheckMobileScreen from "../../hook/useCheckMobileScreen";
import {StringParam, useQueryParam} from "use-query-params";
import IncentiveProgramSelect from "./IncentiveProgramSelect";
import CustomerShippingInfo from "./CustomerShippingInfo";
import ShippingDestinationModal from "../ShippingDestinationModal";
import CalculatePercentDiscountButtonModal from "./CalculateButtonModal";
import BMReadOnly from "../BMReadOnly";
import QuoteNote from "./QuoteNote";
import SubmitSplitChangeAlert from "./SubmitSplitChangeAlert";
import ModelSelectionWizard, {SelectedModelInfo} from "./ModelSelectionWizard";
import QuoteCommentList, { getDefaultCommentTopics } from "./QuoteCommentList";
import { FORM_PERCENT_DISCOUNT, FormValues, QuoteAssemblyExceptionContext, QuoteAssemblyExceptionContextType } from "../../pages/configurator";
import QuoteOwnerAssignment from "./OwnerAssignment";
import QuoteReviewDetail from "../QuoteReviewDetail";
import { useQuoteContext } from "../../contexts/QuoteContext";
import QuoteTrucksButtonModal from "./QuoteTrucksButtonModal";
import { SalesTeamDescription, SalesTeamRole, SelectSalesTeamButtonModal } from "../SelectSalesTeamButton";
import StatesSelector from "../StatesSelector";
import OrderProbability from "./OrderProbability";
import PoNumberEditor from "./PoNumberEditor";
import { FEATURE_DOCUSIGN } from "../../api/features";
import ReviseQuoteButton from "./ReviseQuoteButton";
import useQuotePricing from "../../swr/useQuotePricing";
import FormItem from "antd/es/form/FormItem";

const NOT_FOUND_MSG = "Quote not found"

const AlertStock = ( props: {
  quote:Quote | undefined
}) => {

  if ( !props.quote?.stock ) return <></>

  return <div style={{marginBottom: "1rem"}}>
    <Alert 
      type="warning" 
      message={<div>This quote is for a stock truck (FGI).</div>}
      closable={true}
    />
  </div>
}


const AlertExpired = () => {

  const { quoteAsync, reviseQuote } = useQuoteContext();
  const quote = quoteAsync?.val;

  if ( !(quote && quote.isExpired) ) return <></>

  return <div style={{marginBottom: "1rem"}}>
    <Alert 
      type="error" 
      message={<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>This quote has expired. <ReviseQuoteButton onClick={reviseQuote} style={{marginRight: "1rem"}}>Revise Quote</ReviseQuoteButton></div>}
      closable={true}
    />
  </div>
}

const AlertEngineerLock = ( props:{
  engineer:User | undefined
}) => {
  if ( !props.engineer ) return <></>

  return <div style={{marginBottom: "1rem"}}>
    <Alert 
      type="error" 
      message={`This quote has been locked by engineering.  Please contact ${props.engineer.name} (${props.engineer.email}) with any questions.`}
      closable={true}
    />
  </div>
}

const AlertQuoteStatus = ( props:{
  status:string | undefined
}) => {
  if ( !props.status ) return <></>

  return <div style={{marginBottom: "1rem"}}>
    <Alert
      type="info"
      showIcon
      message={props.status}
      style={{marginBottom: "1rem"}}
    />
  </div>
}

interface QuoteInfoProps {
  isWorking: boolean
  isLocked: boolean
  quoteForm: FormInstance
  truckGvwr: number | undefined
  computedPricingDetailsAsync: AsyncState<PricingBreakdown>
  selectedModel: SelectedModelInfo | undefined
  selectedCustomOptions: CustomOptionType[] | undefined
  formDirty: boolean
  hidePrice: boolean
  getFormValues: () => FormValues
  updatePricingSnapshot: (pricingSnapshotId?: number | undefined ) => void
  handleUpdateDiscountPercent: (val: number) => void
  onQuoteValuesChange: (values: Record<string, any>, _allValues: {}) => void
  populateFormValues: (quote: Quote | undefined) => any
  handleChangePricingView: (pricingDetails: PricingBreakdown) => void
}

const QuoteInfoTab = (props: QuoteInfoProps) => {

  const configurator = useContext(ConfiguratorContext);

  const { quoteAsync, isLocked } = useQuoteContext();
  const quote = quoteAsync?.val;

  const pricing = useQuotePricing({
    quoteId: quote?.quoteId,
    revision: quote?.revision
  });

  const {isWorking, quoteForm, truckGvwr, formDirty, 
    computedPricingDetailsAsync, selectedModel,
    getFormValues, updatePricingSnapshot, handleUpdateDiscountPercent,
    onQuoteValuesChange, populateFormValues, handleChangePricingView, hidePrice } = props;
  const computedPricingDetails = computedPricingDetailsAsync.val;
  const isEngineering = configurator.isEngineering();
  const isFinance = configurator.isFinance();
  const isAdmin = configurator.isAdmin();
  const isSalesDesk = configurator.isSalesDesk();
  const isDealer = configurator.isDealerSales();
  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();
  const isSales = configurator.isInternalSales();
  const isReleaseEngienering = configurator.isReleaseEngineering();
  const isDocusignEnabled = configurator.hasFeature(FEATURE_DOCUSIGN);
  
  const hasExpectationPermission = isSalesDesk || isAdmin || (isSales  && !configurator.hasGlobalViewAccess());

  const intl = useIntl();
  const history = useHistory();
  const params = useParams<Record<string, string>>();

  const isMobile = useCheckMobileScreen();

  const [sidePanelKeyParam, setSidePanelKeyParam] = useQueryParam<string | undefined | null >("side", StringParam);

  const defaultSidePanelKey = sidePanelKeyParam || "pricing-view";
  const [activeSidePanelKey, setActiveSidePanelKey] = useState<string | undefined>( isMobile ? undefined : defaultSidePanelKey);

  const calcPricingDetails = computedPricingDetails || pricing.data;

  const isNewQuote = !params.quoteId?.length; //new quote if no query parameter and has not been saved

  const [leadTime, leadTimeAsync] = useAsyncState<ChangeLeadTime>();

  useEffect(() => {
    if ( quoteAsync?.isDone() ) {
      getLeadTime(leadTimeAsync, quote?.id)
    }
  }, [quote]);

  const getLeadTime = useCallback( debounce(async (leadTimeAsync:AsyncState<ChangeLeadTime>, quoteId?:number): Promise<ChangeLeadTime | undefined> => {
    leadTimeAsync.setLoading();
    try {
      const resp = await configurator.api.getLeadTime(quoteId);
      leadTimeAsync.setDone(resp.data);
      return resp.data;
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get lead time " + errorMsg });
      leadTimeAsync.setFail(e.message);
    }
    return;
  }, 400), []);

  useEffect(() => {
    if (hidePrice) {
      setActiveSidePanelKey(undefined);
    }
    else {
      setActiveSidePanelKey(defaultSidePanelKey);
    }
  }, [hidePrice]);



  const {
    isOrder,
    existPendingSplitChange,
    isRevisionApproved,
    isReadOnly,
    isPendingApproval,
    readOnlyMsg,
    canChangeReadOnly
  } = Utils.getQuoteState(configurator, quoteAsync, isLocked);

  const selectedSalesTeam = Form.useWatch<SalesTeam | undefined>('salesTeam', quoteForm);
  const primarySales = selectedSalesTeam?.sales?.find(v=>v);

  const requiredForOrderSubmit = ( isOrder || isRevisionApproved ) && !formDirty;
  const requiredForQuoteSubmit = !formDirty;

  const isQuoteApprovalProcessStep = isPendingApproval && quote?.latestApproval?.approvalType === ApprovalRequest.QUOTE_APPROVAL && 
    !(quote?.latestApproval.approverRole === ApproverRole.SALES_DESK && isSalesDesk); // Sales desk can modify quote in approval step including concession

  const isQuoteOwner = quote?.owner?.id === configurator.userInfo?.id;
  const hasDealerWritePermission = configurator.hasAnyPermission([Permission.DEALER_ADMIN_WRITE, Permission.DEALER_MANAGEMENT_WRITE]);
     
  const quoteStatusAlert = (): string | undefined => {

    // Assign alert message with increasing priority in following verifications.
    if (quote?.status === QuoteStatus.CANCELLED) {
      return `Order is cancelled. There'll be no change.`
    }

    return;
  }

  const validInteger = async (_rule: any, value: string, _cb: any) => {
    if ( quote?.trucks == undefined || quote?.trucks?.length < 1 ) {
      return;
    }
    if (!value) {
      return;
    }
    if (Number(value) !== parseInt(value, 10) || Number(value) < (quote?.quantity || 1) ) {
      throw new Error();
    }
  };


  const hasIncentiveProgram = !!(getFormValues().incentivePrograms?.length);
  const getMinimumDiscountPercent = () : number => {
    if ( !hasIncentiveProgram ) return QUOTE_DEFAULTS.percentDiscount;

    const selected = getFormValues().incentivePrograms || [];
    const pdLst = selected.map( ip => ip.percentDiscount ).concat(QUOTE_DEFAULTS.percentDiscount);
    return _.max( pdLst ) || QUOTE_DEFAULTS.percentDiscount;
  }

  const getMaximumDiscountPercent = () => {
    if ( getFormValues().incentivePrograms?.length ) return defaultMaxDiscountPercent;

    if (selectedModel) {
      if ((selectedModel.modelInfo?.partNumberSuffix === "C" ) && isDealer) {
        return dealerEditCustomChassisMaximum;
      }
      if (selectedModel.modelInfo.motorFamily === MotorFamily.BEV && isDealer) {
        return dealerEditBevMaximum;
      }
      if (selectedModel.modelInfo.motorFamily === MotorFamily.ICE && isDealer) {
        return dealerEditIceMaximum;
      }
    }
    return defaultMaxDiscountPercent;
  }

  const minDiscountPercent = getMinimumDiscountPercent();
  const maxDiscountPercent = getMaximumDiscountPercent();
  const validateDiscount = async (_rule: any, value: string, _cb: any, enforce?: boolean) => {

    if (!quoteForm.isFieldTouched(FORM_PERCENT_DISCOUNT) && !enforce) {
      return true;
    }

    try {
      const n = parseFloat(value);
      if (n >= minDiscountPercent && n <= maxDiscountPercent ) {
        return true;
      }
    } catch (e) { }
    throw new Error("validation failed");
  };

  const validateGVWRCap = async (_rule: any, value: string, _cb: any) => {
    try {
      //undefined scenario, don't bother warning
      if (truckGvwr == undefined || !value) return true;

      const n = parseInt(value);
      if (n >= 0 && n <= truckGvwr) {
        return true;
      }
    } catch (e) { }
    throw new Error("validation failed");
  };

  const isWithinLeadTimeCheck = (leadTime: number | undefined): boolean => {
    if (isAdminOrEngineering || isSalesDesk || configurator.isProduction()) {
      return false;
    }
    return Utils.isWithinLeadTime(quote?.productionDate, leadTime);
  }

  const gvwrPlaceholder = truckGvwr ? `Limit ${truckGvwr}` : '';


  const layoutStyle = isMobile ? { display: "flex" } : {};

  const isQuoteNotFound = quoteAsync?.isFail() && quoteAsync.err === NOT_FOUND_MSG;
  if ( isQuoteNotFound ) {
    return <div style={layoutStyle}>
      <AssemblySectionMenu />
      <div className="site-layout-background" style={{ marginLeft: isMobile ? "0px" : "75px" }}>
        <Result
          status="error"
          title={quoteAsync?.err}
          extra={ <Button onClick={history.goBack} type="primary" key="console"> Back </Button> }
        />
      </div>
    </div>
  }

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  const getDefaultDisabledMsg = () => {
    return quote?.archived ? "Archvived quote cannot be modified."
      : isReadOnly ? readOnlyMsg
      : isWorking ? "Please wait till background work has completed."
      : undefined;
  }

  const getPercentDiscountDisabledMsg = () => {
    return !maxDiscountPercent ? "Concession cannot be changed."
    : isQuoteApprovalProcessStep ?  "Changing the Concession is disabled during the approval step."
      : getDefaultDisabledMsg();
  }

  const getSelectModelDisabledMsg = () => {
    return ( isOrder && !( isAdmin || isEngineering) ) ? "The model cannot be changed on an order."
      : getDefaultDisabledMsg();
  }

  const getIncentiveProgramDisabledMsg = () => {
    return isQuoteApprovalProcessStep ? "Incentive Programs selection is disabled during the approval step."
      : getDefaultDisabledMsg();
  }

  const getPoNumberDisabledMsg = () => {
    return quote?.archived ? "Archvived quote cannot be modified."
      : isWorking ? "Please wait till background work has completed."
      //: !isQuoteModifiable() ? "This quote cannot be modified."
      //: !isChangeableRevision ? "This revision cannot be modified."
      : undefined;
  }

  const showSalesRequests = !props.selectedCustomOptions?.length && !!quote?.salesRequests?.trim().length;

  const getProductionDateInfo = (productionDate:Date | undefined) : ReactNode => {

    if ( productionDate ) return dayjs(productionDate).format('M/D/YYYY');

    const leadTimeDays = _.max([leadTime?.categoryLeadTimeDays, (leadTime?.standardLeadTimeWeeks || 0) * DAYS_IN_WEEK]);
    if( leadTimeDays ) {
      const dt = dayjs().add(leadTimeDays, 'day').format('M/D/YYYY');
      const categories = leadTime?.leadTimeCategories?.map(c => Utils.stripSortingPrefix(c.name)).join(", ");
      const leadTimeDescription = leadTimeDays === leadTime?.categoryLeadTimeDays
        ? `Production lead time of ${leadTime?.categoryLeadTimeDays} days due to: ${categories}.`
        : `Standard production lead time of ${leadTime?.standardLeadTimeWeeks} weeks.`;

      return <>{dt}
        <Tooltip title={leadTimeDescription}>
          <WarningOutlined style={{color:"orange", marginLeft: ".4rem"}} />
        </Tooltip>
      </>
    }

    return "Not Available";
  }

  const getSalesTeamEngineerDisabled = () : string | undefined => {
    return isDealer ? "Please contact Battle Motors support for any changes."
    : undefined;
  }


  const getSalesTeamSupportDisabled = () : string | undefined => {
    return !(isAdmin || isSalesDesk) ? "Please contact Battle Motors support for any changes."
    : undefined;
  }

  const getSalesTeamDisabled = () : string | undefined => {
    return readOnlyMsg;
  }

  const PricingViewPanel = () => {
    const isPricingDetailsLoading = computedPricingDetailsAsync.isLoading() || pricing.isLoading;

    return <>
      <Title level={5} style={{marginBottom: "1rem"}}> <TotalPriceDisplay
        calcPricingDetails={calcPricingDetails}
        quotePricingDetails={pricing?.data}
      /></Title>

      <Divider />

      <Form.Item 
        label={<strong>Pricing Configuration</strong>}
      >
      {!isDealer
      ? <SelectPricingSnapshot
          value={quote?.pricingConfig?.pricingSnapshot?.id}
          onChange={(pricingSnapshotId) => updatePricingSnapshot(pricingSnapshotId)}
          disabled={!(isAdmin || isFinance)}
        /> 
        : <BMReadOnly value={quote?.pricingConfig.pricingSnapshot?.name}  readOnly={true}><Input /></BMReadOnly>}
      </Form.Item>
      

      <Spin spinning={isPricingDetailsLoading}>
        <PricingView
          pricingDetails={calcPricingDetails}
          onChangePricingDetails={handleChangePricingView}
          incentivePrograms={getFormValues().incentivePrograms?.map( ip => ip.id )}
        />
      </Spin>
    </>
  }

  const canReassignQuoteOwner = ():boolean => {
    const hasAssignPermission =  isQuoteOwner || isAdmin || isSalesDesk || hasDealerWritePermission
    return hasAssignPermission;
  }

  const sidePanelFlex = (activeSidePanelKey && !isMobile) ? "1 1 25rem" : "1 1 auto";

  return (
    <div>
      <Col>
        <div>

          <Row gutter={40} >
            <Col flex={"4 1"}>

              {existPendingSplitChange && 
                <SubmitSplitChangeAlert/>
              }

              <AlertStock quote={quote} />

              <AlertExpired />

              <AlertEngineerLock engineer={quote?.lockedByEngineer} />

              <AlertQuoteStatus status={quoteStatusAlert()} />

              <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Form
                  labelCol={{ flex: '10.7rem' }}
                  labelAlign="left"
                  labelWrap
                  form={quoteForm}
                  onValuesChange={onQuoteValuesChange}
                  initialValues={populateFormValues(quote)}
                >

                  <FormItem name={"dealerAdjustments"} hidden={true}>
                    <Input type="hidden"/>
                  </FormItem>
                  <FormItem name={"nonDiscountOptions"} hidden={true}>
                    <Input type="hidden"/>
                  </FormItem>

                  <Row>
                    <Col span={18}>

                      <Form.Item
                        name="modelInfo"
                        label="Model"
                        rules={[{
                          required: true && !isReadOnly,
                          message: `A model is required.`,
                        }]}
                      >
                        <ModelSelectionWizard
                          disabled={!!getSelectModelDisabledMsg()}
                          onDisabledClick={() => notifyDisabled(getSelectModelDisabledMsg())} />
                      </Form.Item>
                    </Col >

                  </Row>

                  <Row gutter={24}>
                    <Col span={18}>
                      <Form.Item
                        name="gvwrCap"
                        label="GVWR Cap"
                        rules={[{
                          message: `GVWR Cap cannot exceed truck GVWR (${truckGvwr}).`,
                          validator: validateGVWRCap
                        }]} 
                      >
                        <BMReadOnly readOnly={isReadOnly} placeholder="None" dataTestId="gvwr-cap" >
                          <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                            placeholder={gvwrPlaceholder} 
                            style={{width: "100%"}}
                            controls={false}
                            disabled={!selectedModel} 
                          />
                        </BMReadOnly>
                      </Form.Item>
                    </Col>
                    {(isSales) &&
                    <Col span={4}>
                    </Col>
                    }

                  </Row>

                  <Row>
                    <Col span={18}>
                      <Form.Item
                        label="Quote Name"
                        name="quoteName"
                        rules={[
                          {
                            required: true && !isReadOnly,
                            message: "Quote Name is required",
                          },
                        ]}
                        hidden={isReadOnly && !quote?.name}
                      >
                        <BMReadOnly readOnly={isReadOnly && !canChangeReadOnly} placeholder="None"
                          style={{whiteSpace: "pre-wrap", textAlign:"left"}}
                        >
                          <Input />
                        </BMReadOnly>
                      </Form.Item>
                    </Col>
                  </Row>

                  <QuoteNote isReadOnly={isReadOnly} />

                  {showSalesRequests &&
                    <Row>
                      <Col span={18}>
                        <Form.Item
                          label="Sales Requests"
                          name="salesRequests"
                          hidden={(isReadOnly && !quote?.salesRequests?.length)}
                        >
                          <BMReadOnly readOnly={isReadOnly} placeholder="None" >
                            <Input.TextArea style={{ width: 350 }} />
                          </BMReadOnly>
                        </Form.Item>
                      </Col>
                    </Row>}


                  <Row align="middle" >
                    <Col span={8} >
                      <Form.Item
                        wrapperCol={{span: 10}}
                        label="Quantity"
                        name="quantity"
                        rules={[
                          { message: "Must be larger number",
                            validator: validInteger,
                          },
                          { message: "Required", 
                            required: true && !isReadOnly,
                          },
                        ]}
                      >
                        <BMReadOnly readOnly={isReadOnly} placeholder="None" dataTestId="quantity">
                          <InputNumber precision={0} />
                        </BMReadOnly>
                      </Form.Item>
                    </Col>
                    <Col span={2} ></Col>
                    <Col >
                      <Form.Item
                        wrapperCol={{span: 10}}
                        label={ ( !maxDiscountPercent || isReadOnly ) ? "Concession" : `Concession (${minDiscountPercent}-${maxDiscountPercent})` }
                        name={FORM_PERCENT_DISCOUNT}
                        rules={[
                          {
                            required: true && !isReadOnly,
                            message: `Must be a decimal and between ${minDiscountPercent} and ${maxDiscountPercent}`,
                            validator: validateDiscount,
                          },
                        ]}
                      >
                        <BMReadOnly 
                          readOnly={!!getPercentDiscountDisabledMsg()} 
                          onClick={() => notifyDisabled(getPercentDiscountDisabledMsg())}
                          placeholder="None" 
                          renderValue={(v) => v !== undefined ? (v + "%") : undefined  } 
                        >
                          <InputNumber precision={4} 
                            controls={false} 
                            stringMode
                            formatter={(value) => value + '%'} 
                            parser={(value) => value!.replace('%', '')}
                            addonAfter={<CalculatePercentDiscountButtonModal 
                              breakdown={calcPricingDetails} 
                              poNumber={quote?.poNumber}
                              minDiscountPercent={minDiscountPercent}
                              maxDiscountPercent={maxDiscountPercent}
                              validateDiscount={(_rule: any, value: string, _cb) => validateDiscount(_rule, value, _cb, true)}
                              onChange={handleUpdateDiscountPercent}
                            />}/>
                        </BMReadOnly>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={18}>
                      <Form.Item 
                        label="Incentive Program"
                        name="incentivePrograms"
                        hidden={isReadOnly && !quote?.incentivePrograms.length}
                      >
                        <IncentiveProgramSelect modelId={selectedModel?.modelInfo.id} 
                          disabled={!!getIncentiveProgramDisabledMsg()}
                          onDisabledClick={() => notifyDisabled(getIncentiveProgramDisabledMsg())}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {(!isDocusignEnabled || (isSalesDesk || isAdmin)) &&
                  <Row>
                    <Col span={18}>
                      <Form.Item
                        label="PO Number"
                        name="poNumber"
                        hidden={isNewQuote}
                      >
                        <PoNumberEditor
                          disabled={!!getPoNumberDisabledMsg()}
                          onDisabledClick={() => notifyDisabled(getPoNumberDisabledMsg())}
                        />
                      </Form.Item>
                    </Col>
                  </Row>}

                  <Row gutter={12}>
                    <Col span={18}>
                      <Form.Item
                        label="Destination"
                        name="shippingDestination"
                        rules={[
                          {
                            required: ( requiredForQuoteSubmit || requiredForOrderSubmit ) && !isReadOnly,
                            message:
                              "Shipping Destination is required",
                          },
                        ]}
                      >
                        <ShippingDestinationModal disabled={isWithinLeadTimeCheck(leadTime?.shippingDestinationLeadTime) || isReadOnly} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={21}>
                      <Form.Item
                        label="End Customer"
                        name="endCustomer"
                        rules={[
                          {
                            required: ( requiredForQuoteSubmit || requiredForOrderSubmit ) && !isReadOnly,
                            message: "End Customer is required",
                          },
                        ]}
                      >
                        <CustomerEntry disabled={isReadOnly} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        label="State of Registration"
                        name="stateRegistration"
                        rules={[
                          {
                            required: ( requiredForQuoteSubmit || requiredForOrderSubmit ) && !isReadOnly,
                            message: "State of Registration is required",
                          },
                        ]}
                      >
                        <BMReadOnly readOnly={isReadOnly} placeholder="None" >
                          <StatesSelector data-testid="stateRegistrationSelector" includeTbd={true} allowClear={false} />
                        </BMReadOnly>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row >
                    <Col span={18}>
                      {quote?.owner?.dealerName && 
                        <Form.Item label="Dealer" >
                          {quote?.owner?.dealerName}
                        </Form.Item>}
                    </Col>
                  </Row>

                  <Row >
                    <Form.Item
                      label="Requested Ship Date:"
                      name="requestedShipping"
                      hidden={isNewQuote}
                    >
                      <CustomerShippingInfo 
                        disabled={isReadOnly && !canChangeReadOnly}
                      />
                    </Form.Item>
                  </Row>

                  <Row >
                    <Form.Item
                      label="Production Date:"
                      name="productionDate"
                      hidden={!isReleaseEngienering && !isAdmin}
                    >
                      <QuoteTrucksButtonModal 
                        labelText={getProductionDateInfo(quote?.productionDate)} 
                        hideCopy={true}
                      />
                    </Form.Item>
                  </Row>

                  <Row >
                    <Form.Item
                      label="Ready to Ship Date:"
                      name="shippingDate"
                      hidden={!isReleaseEngienering && !isAdmin}
                    >
                      <QuoteTrucksButtonModal 
                        labelText={quote?.shippingDate ? dayjs(quote?.shippingDate).format('M/D/YYYY') : "Not Available"} 
                        hideCopy={true}
                      />
                    </Form.Item>
                  </Row>

                  {!isNewQuote && hasExpectationPermission ? <>
                    <Divider />

                    <Row>
                      <Title style={{textDecoration: "underline"}} level={4}>Forecast</Title>
                    </Row>

                    <Row >
                      <Col span={18} >
                        <Form.Item
                          label="Order Probability:"
                          name="orderProbability"
                          hidden={isNewQuote}
                        >
                          <OrderProbability/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row >
                      <Col span={18} >
                        <Form.Item
                          label="Order Date:"
                          name="expectedOrderDate"
                          hidden={isNewQuote}
                        >
                          <DatePicker
                            allowClear={true}
                            data-testid="request-date-picker"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row >
                      <Col span={18} >
                        <Form.Item
                          label="Ship Date:"
                          name="expectedShipDate"
                          hidden={isNewQuote}
                        >
                          <DatePicker
                            allowClear={true}
                            data-testid="request-date-picker"
                          />
                        </Form.Item>
                      </Col>

                    </Row>
                  </>
                  :<>
                        <Form.Item
                          label="Order Probability:"
                          name="orderProbability"
                          hidden={true}
                        >
                          <OrderProbability/>
                        </Form.Item>
                        <Form.Item
                          label="Order Date:"
                          name="expectedOrderDate"
                          hidden={true}
                        >
                          <DatePicker
                            allowClear={true}
                            data-testid="request-date-picker"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Ship Date:"
                          name="expectedShipDate"
                          hidden={true}
                        >
                          <DatePicker
                            allowClear={true}
                            data-testid="request-date-picker"
                          />
                        </Form.Item>
                  </> }

                  <Divider />

                  <Form.Item 
                    label="Salesperson:" 
                    name="owner" 
                    hidden={isNewQuote}
                  >
                    <BMReadOnly readOnly={isReadOnly && !canChangeReadOnly} placeholder="None"
                      btnStyle={{padding: 0}}
                      renderValue={() => Utils.formatUsername(quote?.owner) }
                      onClick={() => notifyDisabled(getSalesTeamDisabled())}
                    >
                    <QuoteOwnerAssignment
                      disabled={!canReassignQuoteOwner()}
                    />
                    </BMReadOnly>
                  </Form.Item>

                  <Form.Item
                    label={ !!quote?.owner?.dealerId ? "BM Representative(s)" : "Additional Sales" }
                    hidden={selectedSalesTeam?.sales?.length === 1 && primarySales?.id == quote?.owner?.id}
                    name="salesTeam"
                    rules={[{ message: "BM representative is required",
                      required: !!quote?.owner?.dealerId && !isReadOnly && ( requiredForQuoteSubmit || requiredForOrderSubmit )
                    }]}
                  >
                    <BMReadOnly readOnly={isReadOnly && !canChangeReadOnly} placeholder="None"
                      btnStyle={{padding: 0}}
                      renderValue={() => <SalesTeamDescription salesTeam={quote?.salesTeam} role={SalesTeamRole.Sales} readOnly={true} /> }
                      onClick={() => notifyDisabled(getSalesTeamDisabled())}
                    >
                      <SelectSalesTeamButtonModal role={SalesTeamRole.Sales} filter={{
                        dealerId: quote?.owner?.dealerId ,
                        isUserSalesTeam: quote?.owner?.dealerId ? undefined : true
                      }} />
                    </BMReadOnly>
                  </Form.Item>

                    <Form.Item 
                      label="Application Engineer:" 
                      name="salesTeam"
                      hidden={isNewQuote}
                    >
                      <BMReadOnly readOnly={(isReadOnly && !canChangeReadOnly) || isDealer} placeholder="None"
                        btnStyle={{padding: 0}}
                        renderValue={() => <SalesTeamDescription salesTeam={quote?.salesTeam} role={SalesTeamRole.Engineer} readOnly={true} /> }
                        onClick={() => notifyDisabled(getSalesTeamEngineerDisabled())}
                      >
                        <SelectSalesTeamButtonModal role={SalesTeamRole.Engineer} filter={{
                          dealerId: quote?.owner?.dealerId ,
                          isUserSalesTeam: quote?.owner?.dealerId ? undefined : true
                        }} />
                      </BMReadOnly>
                    </Form.Item>

                    <Form.Item
                      label="BM Sales Support:"
                      name="salesTeam"
                    >
                      <BMReadOnly readOnly={(isReadOnly && !canChangeReadOnly) || !(isAdmin || isSalesDesk) } placeholder="None"
                        btnStyle={{padding: 0}}
                        renderValue={() => <SalesTeamDescription salesTeam={quote?.salesTeam} role={SalesTeamRole.Support} readOnly={true} /> }
                        onClick={() => notifyDisabled(getSalesTeamSupportDisabled())}
                      >
                        <SelectSalesTeamButtonModal role={SalesTeamRole.Support} filter={{
                          dealerId: quote?.owner?.dealerId ,
                          isUserSalesTeam: quote?.owner?.dealerId ? undefined : true
                        }} />
                      </BMReadOnly>
                    </Form.Item>

                    <Form.Item
                      label="Viewers:"
                      name="salesTeam"
                    >
                      <BMReadOnly readOnly={(isReadOnly && !canChangeReadOnly) || !(isAdmin || isSalesDesk) } placeholder="None"
                        btnStyle={{padding: 0}}
                        renderValue={() => <SalesTeamDescription salesTeam={quote?.salesTeam} role={SalesTeamRole.Viewer} readOnly={true} /> }
                        onClick={() => notifyDisabled(getSalesTeamSupportDisabled())}
                      >
                        <SelectSalesTeamButtonModal role={SalesTeamRole.Viewer} filter={{
                          dealerId: quote?.owner?.dealerId ,
                          isUserSalesTeam: quote?.owner?.dealerId ? undefined : true
                        }} />
                      </BMReadOnly>
                    </Form.Item>


                </Form>

              </div>
            </Col>
            <Col flex={sidePanelFlex} >
              <SidePanel activeKey={activeSidePanelKey} 
              onChangeActiveKey={(k) => {
                setActiveSidePanelKey(k);
                setSidePanelKeyParam(k);
              }} 
                PricingViewPanel={PricingViewPanel}
              />
            </Col>
          </Row>
        </div>
      </Col>

    </div>
  );
};

const SidePanel = (props:{
  activeKey: string | undefined
  onChangeActiveKey: (k:string | undefined) => void
  PricingViewPanel: () => ReactElement
}) => {

  const { PricingViewPanel } = props;

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const {quoteAsync} = useQuoteContext();
  const quote = quoteAsync?.val;

  const [topicCommentStats, setTopicCommentStats] = useState<Record<CommentTopic,CommentStats> | {} | undefined>();

  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();
  const isMobile = useCheckMobileScreen();

  const leftPanelIconStyle = { fontSize: '150%'};

  const showSidePanel = !isMobile && props.activeKey; 

  useEffect(() => {
    if ( quoteAsync?.isDone() ) {
       loadCommentTopicCount(quote?.quoteId)
        .then( stats => setTopicCommentStats( stats ) );
    }
  }, [ quoteAsync ]);

  const loadQuoteComments = async (quoteId:string | undefined) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.fetchQuoteComments(quoteId);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch comments " + errorMsg });
    }
    return;
  }

  const loadCommentTopicCount = async (quoteId:string | undefined) : Promise<Record<CommentTopic,CommentStats> | {} | undefined> => {
    if( !quoteId ) return;

    const commentLst = await loadQuoteComments(quoteId);
    return commentLst?.map( c => ({
      topic: c.topic,
      total: 1,
      unread: c.lastViewedAt ? 0 : 1,
    }))
    .reduce( ( acc, v ) => {
      const c = acc[ v.topic ];
      acc[ v.topic ] = { 
        total: (c?.total || 0 ) + v.total,
        unread: (c?.unread || 0 ) + v.unread,
      };
      return acc;
    }, {});
  }



  const getCommentTopicCount = (topic: CommentTopic | CommentTopic[]):CommentStats | undefined => {
    if ( !topicCommentStats ) return;

    return [topic].flat()
      .map( t => topicCommentStats[t] ).filter(v => v)
      .reduce( ( acc, v ) => ({
        total: acc.total + v.total,
        unread: acc.unread + v.unread,
      }), {total: 0, unread: 0});
  }

  const handleTabClick = (activeKey: string | undefined) =>  {
    const key = activeKey === props.activeKey? undefined : activeKey;
    props.onChangeActiveKey( key );
  }

  const items = [
    {
      key: "pricing-view",
      label: <>
        <DollarCircleFilled style={leftPanelIconStyle} />
        <Title level={5}>Pricing</Title>
      </>,
      children: showSidePanel && <PricingViewPanel />
    },
    {
      key: "comments",
      label: <>
        {!!getCommentTopicCount(getDefaultCommentTopics(isAdminOrEngineering))?.total &&
        <div style={{position: "absolute", top: -10, right: 30}}>
          <Badge count={getCommentTopicCount(getDefaultCommentTopics(isAdminOrEngineering))?.total} size="small" color={getCommentTopicCount(getDefaultCommentTopics(isAdminOrEngineering))?.unread ? "red" : "grey" } />
        </div>}
        <CommentOutlined style={leftPanelIconStyle}  />
        <Title level={5}>Comments</Title>
      </>,
      children: showSidePanel && <QuoteCommentList />
    },
    {
      key: "documents",
      label: <>
        {!!getCommentTopicCount(CommentTopic.Documents)?.total &&
        <div style={{position: "absolute", top: -10, right: 30}}>
          <Badge count={getCommentTopicCount(CommentTopic.Documents)?.total} size="small" color={getCommentTopicCount(CommentTopic.Documents)?.unread ? "red" : "grey" } />
        </div>}
        <FileOutlined style={leftPanelIconStyle}  />
        <Title level={5}>Documents</Title>
      </>,
      children: showSidePanel && <QuoteCommentList topics={[CommentTopic.Documents]} />
    }];

    if ( isAdminOrEngineering ) {
      items.push( {
        key: "vfdReview",
        label: <>
          <MenuOutlined style={leftPanelIconStyle}  />
          <Title level={5}>VFD Review</Title>
        </>,
        children: showSidePanel && <VfdReviewPanel />
      })
    }


  return <>
    <style>
      {`
        .right-panel .ant-tabs-tab {
          display: flex;
          justify-content: center;
          padding: 0 0 0 1rem !important;
        }
        `}
    </style>

    {showSidePanel &&
    <Divider type="vertical" style={{float: "left", height: "100%", paddingLeft: ".3rem", paddingRight: ".3rem" }} />
    }

    <div className="right-panel" >
      <Tabs
        activeKey={props.activeKey || undefined}
        onTabClick={handleTabClick}
        tabPosition="right"
        items={items}
      />
    </div>
    {isMobile &&
    <Drawer 
      open={!!props.activeKey} 
      onClose={() => handleTabClick(props.activeKey)}
    >
      {(props.activeKey === 'pricing-view' ) && <PricingViewPanel />}
      {(props.activeKey === 'comments' ) && <QuoteCommentList />}
    </Drawer>}
  </>
}

export const VfdReviewPanel = (props:{
  includeAllCustomOptions?: boolean
}) => {

  const quoteContext = useQuoteContext();
  const quote = quoteContext.quoteAsync?.val;

  const includeAllCustomOptions = props.includeAllCustomOptions ?? true;

  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const { quoteAssemblyExceptionLstAsync } = useContext<QuoteAssemblyExceptionContextType>(QuoteAssemblyExceptionContext);
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  useEffect(() => {
    loadReview(reviewAsync, quote?.displayRevisionId);
  }, [quoteContext, quoteAssemblyExceptionLstAsync ])

  const loadReview = useCallback(throttle( async (reviewAsync:AsyncState<QuoteReview>,  quoteRevisionId:number | undefined) : Promise<QuoteReview | undefined> => {
    if ( !quoteRevisionId ) return;


    const dealerView = !quoteContext.adminView;
    const selectedOptions = Object.values(quoteContext.selectedOptions || {}).flat()
    const selectedCustomOptions = quoteContext.selectedCustomOptions?.map(co => co.id );

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, { includeAllCustomOptions, dealerView, selectedOptions, selectedCustomOptions } );
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }, DEFAULT_THROTTLE), []);

  return <Skeleton active loading={reviewAsync.isLoading()} >
    <QuoteReviewDetail review={review} />
  </Skeleton>
}

export const TotalPriceDisplay = (props:{
  quotePricingDetails: PricingBreakdown | undefined
  calcPricingDetails?: PricingBreakdown
}) => {

  const { calcPricingDetails, quotePricingDetails } = props;

  const pricingDetails = calcPricingDetails || quotePricingDetails;

  if ( pricingDetails === undefined ) return <>Dealer Price: Not available yet</>;

  const isDifferent = pricingDetails.totalPrice !== (quotePricingDetails?.totalPrice || 0);
  const tooltip =  isDifferent && <Tooltip title="This price reflects unsaved changes to the quote.">
    <span style={{color: "blue"}}>*</span>
  </Tooltip>

  return <>
    <div>
      Dealer Price: {Utils.formatMoney(pricingDetails.dealerPrice)}{tooltip}
    </div>
    <div>
      Customer Price: <span data-testid="total-price-display" >{Utils.formatMoney(pricingDetails.totalPrice)}</span>{tooltip}
    </div>
  </>
}



export default QuoteInfoTab;

