import {useContext, useEffect, useState} from "react";
import {Assembly, AssemblyMetadata, BaseCategory, Category, CategoryMetadata, CustomOptionType} from "../api/models";
import {ConfiguratorContext, ModelCategoryContext} from "../context";
import {useAsyncState} from "../hook/useAsyncState";
import {Button, Checkbox, Descriptions, Form, Input, InputNumber, Modal, notification, Result, Select, Space, Spin, Tooltip } from "antd";
import {useIntl} from "react-intl";
import Utils from "../util/util";
import { ExclamationCircleOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import {CustomOptionRequest} from "../api";
import TextArea from "antd/es/input/TextArea";
import { MetadataFieldList } from "./metadata_field";
import AssemblySelectionTableWithMetadata from "./AssemblySelectionTableWithMetadata";
import {WizardInstance, WizardStep} from "./Wizard";
import dayjs from "dayjs";
import { useQuoteContext } from "../contexts/QuoteContext";
import Title from "antd/lib/typography/Title";


interface CustomOptionFormValues  {
  id?:number
  key:string
  content?: string
  categoryId: number | undefined
  note?:string
  standardMaterialCost?: number
  laborHours?: number
  disableUpcharge?: boolean
  included?: boolean
  metadata: CustomOptionMetadataFormValues[] | undefined
  similarAssembly?: Assembly | undefined
}

export interface CustomOptionMetadataFormValues {
  id?:number
  categoryMetadata:CategoryMetadata //use the whole object for sortOrder
  valueText?:string
  valueBool?: boolean
  valueNumeric?: number
  valueDecimal?: number
}

function isNewCustomOption(customOption: CustomOptionType | undefined) : boolean {
  if ( !customOption ) return true;
  if (!('id' in customOption)) return true;
  if ( customOption.id === undefined ) return true;
  return false;
}

const defaultCustomOption = {
  included: true,
  key: crypto.randomUUID()
};

const useCustomOptionWizardSteps = (props: {
  isOpen: boolean
  value?:CustomOptionType | undefined
  onChange?:(co:CustomOptionType) => void
  onCancel?:() => void
  categoryId?: number
  form:any
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [_customOption, customOptionAsync] = useAsyncState<CustomOptionType>();
  const { modelCategoriesAsync, loadModelCategories } = useContext(ModelCategoryContext);
  const { quoteAsync } = useQuoteContext();
  const [category, categoryAsync] = useAsyncState<Category>();
  const [initialValues, setInitialValues] = useState<CustomOptionFormValues>();

  const form = props.form;
  const formCategoryId = Form.useWatch( 'categoryId', form );
  const hasCategory = !!formCategoryId;


  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect( () => {
    if ( props.isOpen ) {
      const loadModel = (modelCategoriesAsync?.isInitial() && !modelCategoriesAsync.isLoading() ) 
        ? loadModelCategories
        : Promise.resolve

      categoryAsync.setInit();

      Promise.all([
        loadModel,
        loadCategory(props.value?.category?.id || props.categoryId),
      ])
        .then( ([_modelCategories, category]) => {
          setInitialValues({
            ...(props.value || defaultCustomOption ),
            categoryId: category?.id,
            metadata: buildFormMetadata(props.value, category )
          });
        });
    }

  }, [props.value, props.categoryId, props.isOpen] );

  const isLoading = modelCategoriesAsync?.isLoading() || categoryAsync.isLoading();

  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();

  const hasMetadataPermissions = configurator.isEngineering() || configurator.isAdmin();
  const hasPricingPermissions = configurator.isEngineering() || configurator.isAdmin();
  const pricingConfig = quoteAsync?.val?.pricingConfig;

  const loadCategory = async (id:number | undefined) : Promise<Category | undefined> => {
    if(!id) return;

    try {
      categoryAsync.setLoading();
      const resp = await configurator.api.getCategory(id);

      const category = resp.data;
      categoryAsync.setDone(category);
      return category;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to load category. " + errorMsg;
      notification.error( { message: msg });
      categoryAsync.setFail( msg );
    }

    return;
  };


  const createCustomOption = async (req:CustomOptionRequest) : Promise<CustomOptionType | undefined> => {

    customOptionAsync.setLoading()
    try {
      const resp = await configurator.api.createCustomOption(req)
      customOptionAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create custom option. " + errorMsg;
      notification.error( { message: msg });
      customOptionAsync.setFail(msg);
    }

    return;
  }

  const editCustomOption = async (req:CustomOptionRequest) : Promise<CustomOptionType | undefined> => {

    customOptionAsync.setLoading()
    try {
      const resp = await configurator.api.updateCustomOption(req)
      customOptionAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to edit custom option. " + errorMsg;
      notification.error( { message: msg });
      customOptionAsync.setFail(msg);
    }

    return;
  }

  const deleteCustomOption = async (customOptionId:number) : Promise<boolean> => {

    customOptionAsync.setLoading()
    try {
      await configurator.api.deleteCustomOption(customOptionId)
      customOptionAsync.setDone(undefined);
      return true;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to delete custom option. " + errorMsg;
      notification.error( { message: msg });
      customOptionAsync.setFail(msg);
    }

    return false;
  }


  const handleFirstStep = (nav:WizardInstance | undefined) => {
    form.validateFields(['content', 'categoryId'])
      .then( nav?.nextStep )
      .catch((_e:any) => {
        notification.error({message: "Please fix validation errors." });
      });
  }

  const handleSave = async (nav:WizardInstance | undefined, confirm?:boolean) => {

    const { isRevisionApproved, isOrder } = Utils.getQuoteState(configurator, quoteAsync);
    if ( confirm !== false && isRevisionApproved && !isOrder ) {
      Modal.confirm({
        title: 'Warning',
        content:"These changes will require approval.",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          handleSave(nav, false);
        },
      });
    }
    else {

      try {
        const formValues = await form.validateFields() as CustomOptionFormValues;

        //remove junk
        const { similarAssembly, ...values } = formValues

        const metadata = values.metadata
        ?.map(md => ({
          ...md, 
          categoryMetadataId: md.categoryMetadata.id, 
          //remove junk
          updatedAt:undefined, createdAt: undefined, categoryMetadata: undefined, name: undefined
        }));

        const co:CustomOptionRequest = {
          ...values,
          metadata,
          quoteRevisionId: quoteAsync?.val?.displayRevisionId,
        };

        const customOption = isNewCustomOption(props.value) ? await createCustomOption(co) : await editCustomOption(co);
        if ( customOption ) {

          props.onChange?.( customOption );
        }
      }
      catch(e:any) {
        notification.error({message: "Please fix validation errors." });
      }
    }
  }

  const handleDelete = async (_nav:WizardInstance | undefined) => {
    if ( !props.value?.id ) return;

    try {
      await deleteCustomOption(props.value.id);
      props.onChange?.( props.value );
    }
    catch(e:any) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const MSRPDisplay = () => {
    const materialCost = form.getFieldValue('standardMaterialCost') 
    const laborHours =  form.getFieldValue('laborHours');
    const disableUpcharge =  form.getFieldValue('disableUpcharge');

    const laborCost = laborHours * ( pricingConfig?.laborHourlyCost || 0 );
    const gross = materialCost + laborCost;
    const margin = gross * ( 1 + ( pricingConfig?.margin || 0 ) );
    const net =  disableUpcharge
        ? margin
        : margin / ( 1 - ( pricingConfig?.customOptionMarkup || 0 ) );
    const msrp = net / ( 1 - ( pricingConfig?.dealerMarkup || 0 ) );

    const txt = Utils.formatMoney(msrp);
    return <>{txt}</>;

  }

  const buildFormMetadata = (customOption:CustomOptionType | undefined, category:Category | undefined) : CustomOptionMetadataFormValues[] =>  {

    const customOptionMetadata:CustomOptionMetadataFormValues[] = customOption?.metadata?.filter( md => category?.metadata.some( cm => cm.id === md.categoryMetadata.id  )) || [];

    const categoryMetadata:CustomOptionMetadataFormValues[] = category?.metadata.filter( cm => !customOptionMetadata.some( md  => cm.id === md.categoryMetadata.id  ) ) 
      .map( cm => ({categoryMetadata:cm}) ) || [];

    return [
      ...customOptionMetadata,
      ...categoryMetadata
    ];
  }


  const setCategoryMetadata = async (categoryId:number | undefined) => {
    if( !categoryId ) return;

    const category = await loadCategory(categoryId); 
    updateFormMetadata( category?.metadata.map( categoryMetadata => ({ categoryMetadata, createdAt: new Date() })) || [] );
  }
  const updateFormMetadata = (assemblyMetadata:AssemblyMetadata[]) => {

    const categoryMetadata = category?.metadata || [];

    //limit assembly metadata to active category metadata
    const metadata = assemblyMetadata.filter(md => categoryMetadata.some(cm => cm.id === md.categoryMetadata.id ));

    form.setFieldValue( 'metadata', metadata );
  }
  const updateFormPricing = (standardMaterialCost:number, laborHours:number) => {
    form.setFieldValue( 'standardMaterialCost', standardMaterialCost );
    form.setFieldValue( 'laborHours', laborHours );
  }

  const handleValuesChange = async (values: CustomOptionFormValues, _allValues: CustomOptionFormValues) => {
    if ( 'similarAssembly' in values ) {
      updateFormMetadata( values.similarAssembly?.metadata || [] );

      const snapshot = [...values.similarAssembly?.pricingSnapshots || []].sort((a,b) => dayjs(a.pricingSnapshot.createdAt).isBefore( dayjs(b.pricingSnapshot.createdAt)) ? 1 : -1 )?.[0];
      updateFormPricing( snapshot.standardMaterialCost, snapshot.laborHours );
    }
    if ( 'categoryId' in values ) {
      setCategoryMetadata(values.categoryId);
    }
  }

  const steps:WizardStep[] = [
    {
      key: "customOptionWarning",
      title: "Warning",
      hidden: !isNewCustomOption(props.value),
      body:(_nav) =><div className="customOptionWarning">
        <style>
          {`
          .customOptionWarning .ant-result-content {
          background-color: inherit;
          }
          `}
        </style>
        <Result status={"warning"} >
          <Title level={5}>This custom option will delay ship date by 30+ days and increase your cost by custom part cost + 25%.</Title>
          <Title level={5}>Do you still wish to proceed with the custom request?</Title>
        </Result>
      </div>,
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
          <Button key="nextBtn" type="primary" onClick={() => nav.nextStep()}>Next</Button>
          <Button key="cancelBtn" onClick={props.onCancel}>Cancel</Button>
      </div>

    },
    {
      key: "customOptionContent",
      title: "Custom Option",
      body:(_nav) => <div key="baseStep">
        <Spin spinning={isLoading}>
          <Form.Item name="id" 
            label="id"
            hidden={true}
          >
            <Input />
          </Form.Item>
          <Form.Item name="key" 
            label="key"
            hidden={true}
          >
            <Input />
          </Form.Item>
          <Form.Item name="categoryId" 
            label="Category"
            hidden={!isAdminOrEngineering || !!props.categoryId}
            rules={[{ 
              message: "Required", 
              required: true,
            }]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              options={modelCategoriesAsync?.val?.map((c:BaseCategory) => ({value:c.id, label: Utils.stripSortingPrefix(c.name ) }))}
            />
          </Form.Item>

          <Form.Item name="content" 
            label="Description"
            rules={[{ 
              message: "Required", 
              required: true,
            }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="note" 
            label="Notes"
          >
            <TextArea />
          </Form.Item>

        <Form.Item 
          name="included" 
          valuePropName="checked"
          label="Selected"
        >
          <Checkbox />
        </Form.Item>

        </Spin>
      </div>,
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
        {isNewCustomOption(props.value) 
          ?<Button key="backBtn" onClick={() => nav.prevStep()}>Back</Button>
          :<Button key="cancelBtn" onClick={props.onCancel}>Cancel</Button>}
          {hasMetadataPermissions 
            ? <Button key="nextBtn" disabled={isLoading} onClick={() => handleFirstStep(nav)} type="primary">Next</Button>
            : <Button key="doneBtn" onClick={() => handleSave(nav)} type="primary">Save</Button>
          }
        </Space>
        {!isNewCustomOption(props.value) && 
          <Button key="deleteBtn" className="deleteBtn" danger onClick={() => handleDelete(nav)}>Remove</Button>
        }
      </div>
    },
    {
      key: "customOptionSimilarAssembly",
      hidden: !hasMetadataPermissions || !!props.value?.metadata?.length || !hasCategory,
      title: "Assembly",
      body:(_nav) => <div key="similarStep">
        <div>Choose an assembly that most closely matches the custom option.  Pricing and metadata will be editable in the following steps.</div>
        <Form.Item name="similarAssembly">
          <AssemblySelectionTableWithMetadata category={category} />
        </Form.Item>
      </div>,
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
          <Button key="backBtn" onClick={() => nav?.prevStep()}>Back</Button>
          <Button key="nextBtn" onClick={() => nav?.nextStep()} type="primary">Next</Button>
        </Space>
      </div>
    },
    {
      key: "customOptionMetadata",
      hidden: !hasMetadataPermissions || !hasCategory,
      title: "Metadata",
      body:(_nav) => <div key="similarStep">
        <MetadataFieldList scroll={{y: 500}} />
      </div>,
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
          <Button key="backBtn" onClick={() => nav?.prevStep()}>Back</Button>
          <Button key="nextBtn" onClick={() => nav?.nextStep()} type="primary">Next</Button>
        </Space>
      </div>
    },
    {
      key: "customOptionPrice",
      hidden: !hasPricingPermissions,
      title: "Price",
      body:(_nav) => <div key="priceStep">

        <Form.Item name="standardMaterialCost" 
          label="Material Cost"
        >
          <InputNumber
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            placeholder="Type dollar amount"
            style={{width: "100%"}}
          />
        </Form.Item>
        <Form.Item name="laborHours" 
          label="Labor Hours"
        >
          <Input />
        </Form.Item>
        <Form.Item name="disableUpcharge"
                   label={<>
                     <Tooltip title="Checking disables the +25% custom option markup."
                     ><InfoCircleTwoTone style={{marginRight: ".4rem"}} /></Tooltip>
                     Disable Upcharge
                   </>}
                   valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
            label={<>
              <Tooltip title="Material Cost + Labor Cost + Margin + Custom Option Markup + Standard Markup"
              ><InfoCircleTwoTone style={{marginRight: ".4rem"}} /></Tooltip>
              MSRP
            </>}
          dependencies={["laborHours", "standardMaterialCost", "disableUpcharge"]}
        >
          {() => <div>
            <MSRPDisplay />&nbsp;
          </div>}
        </Form.Item>

      </div>,
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
          <Button key="backBtn" onClick={() => nav?.prevStep()}>Back</Button>
          <Button key="nextBtn" onClick={() => nav?.nextStep()} type="primary">Next</Button>
        </Space>
      </div>
    },
    {
      key: "customOptionSummary",
      title: "Summary",
      hidden: !hasPricingPermissions,
      body:(_nav) => {
        const co = form.getFieldsValue(true) as CustomOptionFormValues;
        return <div key="summaryStep">
          <Descriptions
            column={2}
            items={[
              { label: "Name", children: co.content },
              { label: "Category", children: category?.name },
              { label: "Selected", children: co.included ? "✓" : "" },
              { label: "Notes", span: 2, children: co.note, },
              { label: "Material Price", children: Utils.formatMoney(co.standardMaterialCost) },
              { label: "Labor Hours", children: co.laborHours },
              { label: "Disable Upcharge",  span: 2, children: co.disableUpcharge ? "✓" : "" },
              { label: "MSRP",  span: 2, children: <MSRPDisplay /> },
            ]} /> 
          <div style={{marginTop: ".5rem", color: "rgba(0,0,0,0.45", textDecoration: "underline"}}>Metadata</div>
          <Descriptions items={[...(co?.metadata || [])]
            .sort((a,b) => (a.categoryMetadata.sortOrder || 0 ) - ( b.categoryMetadata.sortOrder || 0 ) )
            ?.map(md => ({label: md.categoryMetadata.name, children: md.valueBool || md.valueDecimal || md.valueText || md.valueNumeric }))} /> 
        </div>},
      footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
          <Button key="backBtn" onClick={() => nav?.prevStep()}>Back</Button>
          <Button key="doneBtn" loading={customOptionAsync.isLoading()} onClick={() => handleSave(nav)} type="primary">Save</Button>
        </Space>
      </div>
    },
  ]; 


  return {
    initialValues,
    handleValuesChange,
    hasMetadataPermissions,
    steps,
  };
}

export default useCustomOptionWizardSteps;

