import {Button, Modal, notification, Result } from "antd";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import { Quote} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import { useQuoteContext } from "../../contexts/QuoteContext";
import BMButton, {BMButtonProps} from "../BMButton";


export const AbandonReviseQuoteButton = (props:Omit<BMButtonProps, 'onChange' | 'onClick'> & {
  onChange?: (q:Quote | undefined) => void
}) => {

  const { onChange, ...btnProps } = props;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const {quoteAsync} = useQuoteContext();
  const quote = quoteAsync?.val;

  const abandonRevision = async () : Promise<Quote|undefined> => {
    if ( !quote ) return;

    try {

      quoteAsync.setLoading();
      const resp = await configurator.api.abandonQuoteApproval(quote.displayRevisionId );
      quoteAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      const msg = "Failed to abandon quote. " + errorMsg;

      notification.error( { message: msg });
      quoteAsync.setFail(e.message);
    }

    return;
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = async () => {
    await abandonRevision();
    props.onChange?.(quoteAsync?.val);
    setIsOpen(false);
  }

  return <>
    <BMButton
      {...btnProps} 
      onClick={() => setIsOpen(true)}
    >Abandon</BMButton>
    <Modal
      open={isOpen}
      okText="Confirm"
      onOk={handleClick}
      onCancel={() => setIsOpen(false)}
      confirmLoading={quoteAsync?.isLoading()}
      centered
    >
      <Result
        status="info"
        title="This will abandon changes and revert to the previous revision."
      />
    </Modal>
  </>
}


export const ReviseQuoteButton = (props:BMButtonProps) =>  {

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e:any) => {
    props.onClick?.(e);
    setIsOpen(false);
  }

  const activeStyle = !props.disabled ? {borderColor: "#1677ff"} : undefined;

  return <>
    <BMButton style={activeStyle} 
      {...props} 
      onClick={() => setIsOpen(true)}
    />
    <Modal
      open={isOpen}
      okText="Confirm"
      onOk={handleClick}
      onCancel={() => setIsOpen(false)}
      centered
    >
      <Result
        status="warning"
        title="Changes will require approval."
      />
    </Modal>
  </>
}


export default ReviseQuoteButton;

