import { ChangeEvent } from "react";
import { Checkbox, Col, Input, Row, Select } from "antd";
import { Category } from "../../api/models";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import useCategories from "../../swr/useCategories";

const AssemblyFilter = (props:{
  categoryId: number | undefined
  dataFilter: string | undefined
  hideObsolete: boolean | undefined
  updateFilter: (c:Category | undefined, df: string | undefined, hideObsolete: boolean | undefined ) => void
}) => {

  const categories = useCategories().data;
  const { categoryId, dataFilter, updateFilter } = props;

  const selectCategoryById = (id:number | undefined) => categories?.find(c => c.id === id);
  const onFilterChange = (e:ChangeEvent<HTMLInputElement>) => {
    const dataFilter = e.target.value;
    const category = selectCategoryById(categoryId);
    updateFilter(category, dataFilter, props.hideObsolete);
  };

  const onCategoryChange = (e:number) => {
    const category = selectCategoryById(e);
    updateFilter(category, dataFilter, props.hideObsolete);
  };

  const handleHideObsolete = (e:CheckboxChangeEvent) => {
    const category = selectCategoryById(categoryId);
    const hideObsolete = e.target.checked ? true : undefined;
    updateFilter(category, dataFilter, hideObsolete );
  };

  return (
    <Row gutter={16}>
      <Col>
        <span>Category : </span>
        <Select
          value={categoryId}
          allowClear
          showSearch
          style={{ width: "250px", marginLeft: "1rem" }}
          onChange={onCategoryChange}
          placeholder="Filter by a category"
          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {categories?.map(cat => (
            <Select.Option key={cat.id} value={cat.id}>
              {cat.name}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col>
        <span style={{marginLeft: "10px"}}>Filter : </span>
        <Input
          allowClear
          style={{ width: "250px", marginLeft: "1rem" }}
          onChange={onFilterChange}
          value={dataFilter}
          placeholder="Enter text to filter list"
        />
      </Col>
      <Col>
        <span style={{marginLeft: "10px"}}>Hide Obsolete : </span>
        <Checkbox
          value={props.hideObsolete}
          onChange={handleHideObsolete}
        />
      </Col>
    </Row>
  );
}


export default AssemblyFilter
