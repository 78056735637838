import {Button, notification, Form, Spin, Input, Result, Drawer} from "antd";
import {useForm} from "antd/es/form/Form";
import Title from "antd/lib/typography/Title";
import {ValidateFields} from "rc-field-form/lib/interface";
import {useCallback, useContext, useState} from "react";
import {useIntl} from "react-intl";
import {ApprovalDiff, CustomOptionProcessingStatus, CustomOptionType, Quote, QuoteReview, RevisionChangeDto} from "../../api/models";
import {ConfiguratorContext, ModelCategoryContext} from "../../context";
import {useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import BMButton, {BMButtonProps} from "../BMButton";
import ApprovalDiffTable from "../Table/ApprovalDiffTable";
import ModalWizard from "../ModalWizard";
import QuoteReviewDetail from "../QuoteReviewDetail";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { RequestPurchaseOrderFormValue, useRequestPoSteps } from "../RequestPoModalButton";
import DocumentSignUI from "../DocusignUI";
import { RequestPurchaseOrder } from "../../api";
import { WizardInstance, WizardStep } from "../Wizard";
import useQuotePricing from "../../swr/useQuotePricing";

const SubmitDocusignSalesChangeButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
  onValidate?: () => Promise<ValidateFields | undefined>
}) => {

  const { onChange:a, onValidate:b, ...btnProps } = props;

  const {quoteAsync, adminView} = useQuoteContext();
  const quote = quoteAsync?.val;
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
  const [docusignLoading, setDocusignLoading] = useState<boolean>(false);
  const [revisionChanges, revisionChangesAsync] = useAsyncState<RevisionChangeDto>();
  const [_customOptions, customOptionsAsync] = useAsyncState<CustomOptionType[]>();
  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const [form] = useForm();
  const dealerRequest = Form.useWatch<string>('dealerRequest', form);
  const hidePrice = !configurator.isAdmin() && !configurator.isEngineering() && !configurator.isSalesDesk();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [purchaseOrderRequest, setPurchaseOrderRequest] = useState<RequestPurchaseOrder>();

  const submitChangeOrder = async () : Promise<Quote|undefined> => {
    if ( !quote ) return;

    try {

      quoteAsync.setLoading();
      const resp = await configurator.api.submitChangeOrder(quote.displayRevisionId, dealerRequest );
      quoteAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      const msg = "Failed to create change order. " + errorMsg;

      notification.error( { message: msg });
      quoteAsync.setFail(e.message);
    }

    return;
  };


  const loadCustomOptions = async (revId: number | undefined) => {
    if ( !revId ) return;

    try {

      customOptionsAsync.setLoading();
      const resp = await configurator.api.getCustomOptions(revId);
      const customOptions = resp.data.filter(co => co.processingStatus === CustomOptionProcessingStatus.NOT_SUBMITTED_YET);
      customOptionsAsync.setDone(customOptions);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      const msg = "Failed to load custom options. " + errorMsg;

      notification.error( { message: msg });
      customOptionsAsync.setFail(e.message);
    }

    return;
  }

  const loadReview = async (quoteRevisionId:number | undefined) : Promise<QuoteReview | undefined> => {
    if ( !quoteRevisionId ) return;

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, {dealerView:!adminView} );
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }

  const loadDiff = async () : Promise<ApprovalDiff | undefined> => {
    if ( !quote ) return;

    try{
      revisionChangesAsync.setLoading();
      const resp = await configurator.api.verifyRevisionChange(
        {
          quoteId: quote.id,
          verifyingRevisionId: quote.displayRevisionId,
          compareCurrentRevision: true,
        }
      );
      revisionChangesAsync.setDone(resp.data);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response.data.message });
      const msg = "Failed to get change difference. " + errorMsg;

        notification.error( { message: msg });
        revisionChangesAsync?.setFail(e.message);
    }     
  }

  const requestPurchaseOrder = async (quoteRevisionId: number | undefined, req:RequestPurchaseOrderFormValue) : Promise<void> => {
    if ( !quoteRevisionId) return;

    setDocusignLoading(true);
    try {
      await configurator.api.requestPo(quoteRevisionId, req)
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
    }
    setDocusignLoading(false);
  }

  const handleChangeRequest = (nav:WizardInstance) => {

    if ( missingPo || requiresNewPo ) {
      nav.nextStep();
      return;
    }

    submit();
  }

  const handleConfirmSubmit = async () => {

    await Promise.all([
          loadCustomOptions( quote?.displayRevisionId ),
          loadDiff(),
          loadReview(quote?.displayRevisionId)
    ]);

    setIsOpen( true );
  }

  const submit = async () => {
    setDisableSubmit(true);
    const quote = await submitChangeOrder();
    if ( quote ) {
      props.onChange?.(quote);

      setIsOpen(false);
    }
  }

  const handleCancel = () => {
    setDisableSubmit(false);
    setIsOpen(false)
  }

  const handleFinished = async (formValues:RequestPurchaseOrderFormValue) => {
    setIsOpen(false);

    //dealers sign the PO in-app
    const isDealer = configurator.isDealerSales();
    if ( isDealer ) {
      setPurchaseOrderRequest(formValues)
      setShowDrawer(true);
    }
    else {
      await requestPurchaseOrder( quote?.displayRevisionId, formValues );
      submit();
    }
  }

  const handleDoneSigning = () => {
    setShowDrawer(false);
    submit();
  }

  const handleSkipPo = () => {
    submit();
  }

  const isWorking = !!props.loading || quoteAsync?.isLoading() || reviewAsync.isLoading() || revisionChangesAsync.isLoading() || customOptionsAsync.isLoading() || docusignLoading || showDrawer;

  const {steps: docusignSteps} = useRequestPoSteps({
    loading: isWorking,
    onFinished: handleFinished,
    onSkip: handleSkipPo
  })


  let steps:WizardStep[] = [
    {
      key:1,
      body: (nav) => <Result status="warning" 
        title={"The following issues with the order have been identified."}
        subTitle={<Title level={5}>Continue to submit for approval?</Title> }
      >
        <QuoteReviewDetail 
          review={review} 
          ignoreDashDrawing={true} 
        />
      </Result>,
      footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
        <Button key="back" onClick={() => setIsOpen(false)} >Cancel</Button>
      </div>,
      hidden: !Utils.reviewHasErrors(review)
    },
    {
      key:2,
      body: (nav) => 
        <Spin spinning={revisionChangesAsync.isLoading() || quoteAsync?.isLoading()}>

          {revisionChanges?.dealerRequest && 
            <div style={{marginBottom: "2rem", marginTop: "1rem"}}>
              <Title level={5}>Dealer/Sales Change Request: </Title>
              <span>{revisionChanges.dealerRequest}</span>
            </div>
          }
          {revisionChanges?.diffDto?.priceDiff && 
            <div style={{textAlign: "right", fontWeight: "bold"}}>
              {"Price Change: " + Utils.formatMoneyWithSign(Number(revisionChanges.diffDto?.priceDiff?.afterPrice) - Number(revisionChanges.diffDto?.priceDiff?.beforePrice))}
            </div>
          }
          <ApprovalDiffTable diff={revisionChanges?.diffDto}  hidePriceChange={hidePrice}/>
          <Form
            form={form}
            style={{marginTop: "1rem"}}
          >
            <Form.Item
              name="dealerRequest"
              label="Dealer/Sales Change Request"
              rules={[{ required: true, message: "Dealer/Sales Change Request is required" }]}
            >
              <Input placeholder={'Please enter your request here.'} />
            </Form.Item>
          </Form>

        </Spin>,
      footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="next" type="primary" disabled={!dealerRequest || disableSubmit} onClick={() => handleChangeRequest(nav)} >Next</Button>
        <Button key="back" onClick={nav.prevStep} >Back</Button>
      </div>,
    }
  ] ;   


  const hasPoDocuments = !!quote?.poNumber?.documents.length;
  const missingPo = !hasPoDocuments;

  const pricing = useQuotePricing({
    quoteId: quote?.quoteId,
    revision: quote?.revision
  }).data;

  const unitPrice = pricing?.dealerPrice || 0;
  const totalPrice = ( quote?.quantity || 0 ) * unitPrice;
  const poAmount = quote?.poNumber?.amount;
  const isPoAmountMatch = poAmount === unitPrice || poAmount === totalPrice;
  const requiresNewPo = !isPoAmountMatch;

  if ( missingPo ) {
    steps.push( {
      key:"poWarningStep",
      body: (_nav) => <Result status="warning" 
        title={"This order is missing a Purchase Order."}
      />,
      footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="next" type="primary" onClick={() => nav.nextStep()} >Next</Button>
        <Button key="cancel" onClick={() => nav.prevStep()} >Back</Button>
      </div>,
    });
  }
  else if ( requiresNewPo ) {
    steps.push({
      key:"poAmountMatchStep",
      body: (_nav) => <Result status="warning" 
        title={"A new purchase order is required."}
      />,
      footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="next" type="primary" onClick={() => nav.nextStep()} >Next</Button>
        <Button key="cancel" onClick={() => nav.prevStep()} >Back</Button>
      </div>,
    });
  }
    
  if ( missingPo || requiresNewPo ) {
    steps = steps.concat(docusignSteps);
  }

  return <>
    <BMButton
      type="primary"
      onClick={handleConfirmSubmit}
      {...btnProps}
      loading={isWorking}
    >
      Submit
    </BMButton>
    <ModalWizard
      title={'Change Order Submission'}
      showSteps={false}
      centered
      open={isOpen}
      onCancel={handleCancel}
      width={'50rem'}
      steps={steps}
    />
    <Drawer
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      width={"100%"}
      destroyOnClose={true}
    >
      <Spin spinning={drawerLoading} tip="One moment.  Preparing your order." >
        <div style={{display: "flex", justifyContent: "center", padding: "1rem"}}>
          <DocumentSignUI quoteRevisionId={quote?.displayRevisionId} details={purchaseOrderRequest} onDone={handleDoneSigning} onLoad={(l) => setDrawerLoading(l) } />
        </div>
      </Spin>
    </Drawer>

  </>
}

export default SubmitDocusignSalesChangeButton;
