import {notification} from "antd";
import { CancelTokenSource } from "axios";
import _, { throttle } from "lodash";
import {useCallback, useContext, useEffect, useRef} from "react";
import {useIntl} from "react-intl";
import {AssemblyFilterOptions} from "../api";
import {AsyncData} from "../api/async_data";
import {Assembly, BaseCategory, CabStyle, CabStyles, CAB_STYLE_CATEGORY_ID_STR, CAB_STYLE_METADATA_NAME, CategoryIdAssembliesIdMap, DefaultCabFuelImageLst, DEFAULT_METADATA, DEFAULT_THROTTLE, FuelType, FuelTypes, FUEL_TYPE_CATEGORY_ID_STR, FUEL_TYPE_METADATA_NAME, ImageAsset, MISSING_IMAGE, PAGINATION_MAX_PAGE_SIZE} from "../api/models";
import {ConfiguratorContext} from "../context";
import {AsyncState, useAsyncRef, useAsyncState} from "./useAsyncState";
import useCategories from "../swr/useCategories";
import useBaseCategories from "../swr/useBaseCategories";

interface ModelSelection {
  fetch: (modelId:number | undefined, fuelType:FuelType | undefined, cabStyle:CabStyle  | undefined ) => Promise<CategoryIdAssembliesIdMap | undefined>
  fetchCabStyle:  (selections: CategoryIdAssembliesIdMap | undefined) => Promise<CabStyle | undefined>
  fetchFuelType:  (selections: CategoryIdAssembliesIdMap | undefined) => Promise<FuelType | undefined>
}

interface CabStyleImage {
  imageUri: string
  cabStyle: CabStyle
  fuelType: FuelType
}
export const useCabStyleImage = () :CabStyleImage[] => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [cabStyleImage, cabStyleImageAsync] = useAsyncState<ImageAsset[]>();

  useEffect(() => {
    loadCabStyles(cabStyleImageAsync);
    return () => {
      loadCabStyles.cancel();
    }
  }, []);

  const loadCabStyles = useCallback(throttle( async ( cabFuelImageAsync:AsyncState<ImageAsset[]>) : Promise<ImageAsset[] | undefined> => {

  const search = "cabStyle";

    try {
      cabFuelImageAsync.setLoading();
      const resp = await configurator.api.fetchImageAssets({search, page: 0, size: PAGINATION_MAX_PAGE_SIZE})
      cabFuelImageAsync.setDone( resp.data.content );
      return resp.data.content
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load cab model image. " + errorMsg });
      cabFuelImageAsync.setFail( e.message );
    }

    return;
  }, DEFAULT_THROTTLE), []);

  const cabFuelImageLst = cabStyleImage?.map( csi => ({
    imageUri: csi.url,
    cabStyle: Object.values( CabStyles ).find( cs => csi.tags.some( t => t.toUpperCase() === cs.name.toUpperCase() ) ),
    fuelType: Object.values( FuelTypes ).find( cs => csi.tags.some( t => t.toUpperCase() === cs.name.toUpperCase() ) )
  })) 
  .filter( cs => cs.fuelType && cs.cabStyle ) || [];

  //dedup - take first cabStyle & fuelType combintation
  const rslt = [ ...cabFuelImageLst, ...DefaultCabFuelImageLst ].reduce( (p, v) => {

    const key = [ v.cabStyle, v.fuelType ].map(i => i?.name ).join( "-" );

    if ( p[ key ] ) return p;
    p[ key ] = v;
    return p;
  }, {});

  return Object.values(rslt);

}

export const useModelSelection = () : [ ModelSelection ] => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const categories = useBaseCategories().data;

  const filteredAssembliesAsync = useAsyncRef<{filter:AssemblyFilterOptions, lst:Assembly[]}>();
  const modelCategoriesAsync = useAsyncRef<BaseCategory[]>([]);
  const computedSelectionsAsync = useAsyncRef<CategoryIdAssembliesIdMap>();
  const assemblyCacheRef = useRef<Record<number,AsyncData<Assembly>>>({});

  const filteredAssemblies = filteredAssembliesAsync.val;

  const loadModelCategories = async ( modelId?:number) : Promise<BaseCategory[] | undefined> => {
    if (!modelId) return;

    try {
      modelCategoriesAsync.setLoading();
      const resp = await configurator.api.getModelCategories(modelId);
      const categories = resp.data;
      modelCategoriesAsync.setDone( categories );
      return categories;
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load model categories. " + errorMsg });
      modelCategoriesAsync.setFail( e.message );
    }

    return;
  };


  const getSelectedAssemblyIdByCategoryIdStr = (categoryIdStr:string, selections: CategoryIdAssembliesIdMap, categories:BaseCategory[] | undefined) : number[] | undefined => {
    if ( !categories?.length  ) return;

    const category = categories?.find(c => c.categoryId.toLowerCase() === categoryIdStr );
    if( !category ) return;

    return selections[ category.id ];
  }


  const fetchAssembly = async (assemblyId:number) : Promise<Assembly | undefined> => {

    const assemblyData =  assemblyCacheRef.current[ assemblyId ] ?? new AsyncData<Assembly>();

    assemblyCacheRef.current[assemblyId] = assemblyData.loading();

    try {

      const resp = await configurator.api.getAssembly(assemblyId);
      const assembly = resp.data;
      assemblyCacheRef.current[assemblyId] = assemblyData.done(assembly);

      return assembly;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load assembly. " + errorMsg });
      assemblyCacheRef.current[assemblyId] = assemblyData.fail(errorMsg);
    }

    return;
  }

  /***********************************************/
  const getFuelTypes = (assemblyLst:Assembly[]) : FuelType | undefined => {
    const defaultLst =  assemblyLst.filter(assembly => assembly?.metadata.find(md => md.name?.toUpperCase() === DEFAULT_METADATA)?.valueText?.toUpperCase() === DEFAULT_METADATA );
  
    const lst =  ( defaultLst.length ) ? defaultLst : assemblyLst;

    return lst.flatMap( assembly => {
      const valueText = assembly?.metadata.find(md => md.name?.toUpperCase() === FUEL_TYPE_METADATA_NAME.toUpperCase())?.valueText;
      return Object.values( FuelTypes ).filter( cm => cm.valueText.toUpperCase() === valueText?.toUpperCase() );
    })
    .filter(v=>v)
    .find(v=>v);
  }

  /*
  const getCachedFuelType = (selections: CategoryIdAssembliesIdMap) : FuelType | undefined => {

    const categories = allCategories;

    const assemblyIdLst = getSelectedAssemblyIdByCategoryIdStr( FUEL_TYPE_CATEGORY_ID_STR, selections, categories );
    if( !assemblyIdLst?.length ) return;

    const lst = assemblyIdLst
    .map(id =>  assemblyCacheRef.current[ id ]?.val )
    .filter(v=>v) as Assembly[];
    
    return getFuelTypes(lst);
  }
  */

  const fetchFuelType = async (selections: CategoryIdAssembliesIdMap | undefined) : Promise<FuelType | undefined> => {
    if ( !selections ) return;

    if( !categories ) return;

    const assemblyIdLst = getSelectedAssemblyIdByCategoryIdStr( FUEL_TYPE_CATEGORY_ID_STR, selections, categories );
    if( !assemblyIdLst?.length ) return;

    const lst = (await Promise.all( assemblyIdLst.map(id => fetchAssembly(id) ) ))
    .filter(v=>v) as Assembly[];

    return getFuelTypes(lst);
  }
  /***********************************************/

  /***********************************************/
  const getCabModels = (assemblyLst:Assembly[]) : CabStyle | undefined => {

    const defaultLst =  assemblyLst.filter(assembly => assembly?.metadata.find(md => md.name?.toUpperCase() === DEFAULT_METADATA)?.valueText?.toUpperCase() === DEFAULT_METADATA );
  
    const lst = ( defaultLst.length ) ? defaultLst : assemblyLst;

    return lst.flatMap( assembly => {
      const valueText = assembly?.metadata.find(md => md.name?.toUpperCase() === CAB_STYLE_METADATA_NAME.toUpperCase())?.valueText;
      return Object.values( CabStyles ).filter( cm => cm.valueText.toUpperCase() === valueText?.toUpperCase() );
    })
    .filter(v=>v)
    .find(v=>v);
  }

  /*
  const getCachedCabModel = (selections: CategoryIdAssembliesIdMap) : CabStyle | undefined => {

    const categories = allCategories;

    const assemblyIdLst = getSelectedAssemblyIdByCategoryIdStr( CAB_STYLE_CATEGORY_ID_STR, selections, categories );
    if( !assemblyIdLst?.length ) return CabStyles.CUSTOM;

    const lst = assemblyIdLst
    .map(id =>  assemblyCacheRef.current[ id ]?.val )
    .filter(v=>v) as Assembly[];
    
    return  getCabModels(lst);
  }
  */

  const fetchCabStyle = async (selections: CategoryIdAssembliesIdMap | undefined) : Promise<CabStyle | undefined> => {
    if (!selections) return;

    if( !categories ) return;

    const assemblyIdLst = getSelectedAssemblyIdByCategoryIdStr( CAB_STYLE_CATEGORY_ID_STR, selections, categories );
    if( !assemblyIdLst?.length ) return CabStyles.CUSTOM;

    const lst = (await Promise.all( assemblyIdLst.map(id => fetchAssembly(id) ) ))
    .filter(v=>v) as Assembly[];

    return  getCabModels(lst);
  }
  /***********************************************/

  const fetchFilteredAssemblies = async ( filter:AssemblyFilterOptions ) : Promise<Assembly[] | undefined> => {

    if ( _.isEqual(filter, filteredAssemblies?.filter) ) {
      return filteredAssemblies?.lst;
    }

    filteredAssembliesAsync.setLoading();

    try {
      const resp = await configurator.api.fetchFilteredAssemblies(filter);

      const lst = resp.data.content;
      filteredAssembliesAsync.setDone({
        filter,
        lst,
      });

      return lst;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load filtered assembly list. " + errorMsg });
      filteredAssembliesAsync.setFail(errorMsg)
    }

    return;
  }


  const getCabStyleAssemblyByModelId = async ( modelId:number, cabStyle:CabStyle ) : Promise<Assembly | undefined> => {

      const filter = {
        modelId,
        categoryIdStr: CAB_STYLE_CATEGORY_ID_STR,
        size: PAGINATION_MAX_PAGE_SIZE,
      }

      const assemblyLst = (await fetchFilteredAssemblies(filter))?.filter( asm => {
          const cabStyleName = asm.metadata.find(md => md.name?.toUpperCase() === CAB_STYLE_METADATA_NAME.toUpperCase() )?.valueText
          return cabStyleName?.toUpperCase() === cabStyle.valueText?.toUpperCase();
        });

      const defaultLst =  assemblyLst?.filter(assembly => assembly?.metadata.find(md => md.name?.toUpperCase() === DEFAULT_METADATA)?.valueText?.toUpperCase() === DEFAULT_METADATA );

      const lst =  ( defaultLst?.length ) ? defaultLst : assemblyLst;
      return lst?.find( v => v );
  }

  const getFuelTypeAssemblyByModelId = async ( modelId:number, fuelType:FuelType ) : Promise<Assembly | undefined> => {

      const filter = {
        modelId,
        categoryIdStr: FUEL_TYPE_CATEGORY_ID_STR,
        size: PAGINATION_MAX_PAGE_SIZE,
      }

      const assemblyLst = (await fetchFilteredAssemblies(filter))?.filter( asm => {
        const fuelTypeName = asm.metadata.find(md => md.name?.toUpperCase() === FUEL_TYPE_METADATA_NAME.toUpperCase() )?.valueText
        return fuelTypeName?.toUpperCase() === fuelType.valueText?.toUpperCase();
      });

      const defaultLst =  assemblyLst?.filter(assembly => assembly?.metadata.find(md => md.name?.toUpperCase() === DEFAULT_METADATA)?.valueText?.toUpperCase() === DEFAULT_METADATA );

      const lst =  ( defaultLst?.length ) ? defaultLst : assemblyLst;
      return lst?.find( v => v );
  }


  const computeAutoSelections = async (modelId:number, options:CategoryIdAssembliesIdMap | undefined, latestAssembly?:number  ) : Promise<CategoryIdAssembliesIdMap | undefined> => {

    try {

      computedSelectionsAsync.setLoading()
      const currentSelections = options ? Object.values(options).flat() : [];
      const resp = await configurator.api.computeAutoSelections(modelId, { currentSelections, latestAssembly } );
      computedSelectionsAsync.setDone( resp.data.selections );
      return resp.data.selections;
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load compute options. " + errorMsg });
      computedSelectionsAsync.setFail(e.message);
    }

    return;
  };

  const getSelections = async (modelId:number | undefined, fuelType:FuelType | undefined, cabStyle:CabStyle | undefined) : Promise<CategoryIdAssembliesIdMap | undefined> => {

    if ( !modelId ) return;

    const selections:CategoryIdAssembliesIdMap = {};

    const categories = await loadModelCategories(modelId);

    //select fuel type
    let fuelAssembly:Assembly | undefined;
    if ( fuelType ) {
      fuelAssembly = await getFuelTypeAssemblyByModelId( modelId, fuelType )
      if ( fuelAssembly ) {
        const fuelTypeCategory = categories?.find(c => c.categoryId.toLowerCase() === FUEL_TYPE_CATEGORY_ID_STR );
        if ( fuelTypeCategory ) {
          selections[ fuelTypeCategory.id ] = [ fuelAssembly.id ];
        }
      }
    }

    //select cab style 
    let cabAssembly:Assembly | undefined;
    if ( cabStyle ) {
      cabAssembly = await getCabStyleAssemblyByModelId( modelId, cabStyle );
      if ( cabAssembly ) {
        const cabStyleCategory = categories?.find(c => c.categoryId.toLowerCase() === CAB_STYLE_CATEGORY_ID_STR );
        if ( cabStyleCategory ) {
          selections[ cabStyleCategory.id ] = [ cabAssembly.id ];
        }
      }
    }

    const autoSelections = await computeAutoSelections( modelId, selections, cabAssembly?.id || fuelAssembly?.id )
    const options = autoSelections || selections;
    return options;
  }

  return [ {
    fetch:getSelections,
    fetchFuelType,
    fetchCabStyle,
  }];
}
