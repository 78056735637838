import styles from './InventoryDetail.module.css'
import Title from "antd/lib/typography/Title";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  DrawerProps,
  Form,
  Modal,
  notification,
  Result,
  Row,
  Skeleton,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip
} from "antd";
import axios, { CancelTokenSource } from "axios";
import { throttle } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { NotFoundError } from "../api/errors";
import { Approval, ApprovalAction, ApprovalDiff, ApproverRole, AssemblyInfo, AXIOS_CANCEL_MSG, BaseCategory, CommentTopic, CustomOptionType, Quote, QuoteAssemblyException, QuoteAssemblyExceptionType, QuoteComment, Performance, RevisionType, CategoryTags, DEFAULT_THROTTLE, DocusignPoRequestDetail} from "../api/models";
import QuoteCommentList from "../components/Quote/QuoteCommentList";
import { VfdReviewPanel } from "../components/Quote/QuoteInfoTab";
import { ComponentLocationContents, ConfigurationTabContents, DetailsTabContents, PerformanceContents, PricingTabContents, QuoteHeader} from "../components/Quote/QuoteQuickView";
import { ConfiguratorContext, ModelCategoryContext, ModelCategoryContextType } from "../context";
import QuoteContextProvider, { useQuoteContext } from "../contexts/QuoteContext";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { CheckOutlined } from "@ant-design/icons";
import Utils from '../util/util';
import ApprovalDiffTable from '../components/Table/ApprovalDiffTable';
import dayjs from 'dayjs';
import ApprovalTransitionInfo from '../components/ApprovalTransitionInfo';
import BMButton, { BMButtonProps } from '../components/BMButton';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import EditCustomOptionButtonModal from '../components/EditCustomOptionButtonModal';
import React from 'react';
import ApprovalResult from '../components/ApprovalResult';
import RequestPoModalButton from '../components/RequestPoModalButton';
import ModalWizard from '../components/ModalWizard';
import { FEATURE_DOCUSIGN } from '../api/features';
import useQuotePricing from "../swr/useQuotePricing";

const ReleaseEngineeringRoles = [ ApproverRole.RELEASE_ENGINEERING, ApproverRole.PROCUREMENT ];

const ApprovalDetailPage = () => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const cancelLoadQuoteTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadApprovalTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadPoRequestDetailSourceRef = useRef<CancelTokenSource>();
  const params = useParams<{approvalId?:string|undefined}>();
  const [showComments, setShowComments] = useState<boolean>(false);
  const [quoteCommentCnt, setQuoteCommentCnt] = useState<number | undefined>();
  const [showApprovalResults, setShowApprovalResults] = useState<boolean>(false);

  const [approval, approvalAsync] = useAsyncState<Approval>();
  const [quote, quoteAsync] = useAsyncState<Quote>();
  const topics = [ CommentTopic.UserComment, CommentTopic.SystemActivity ]
  const [customOptionLst, customOptionLstAsync] = useAsyncState<CustomOptionType[]>();
  const [quoteAssemblyExceptionLst, quoteAssemblyExceptionLstAsync] = useAsyncState<QuoteAssemblyException[]>();
  const [performance, performanceAsync] = useAsyncState<Performance>();
  const [componentLocations, componentLocationsAsync] = useAsyncState<Record<string, string>>();
  const [poRequestDetail, poRequestDetailAsync] = useAsyncState<DocusignPoRequestDetail>();

  useEffect(() => {
    loadQuoteApproval(approvalAsync, params.approvalId)
      ?.then(approval => {
        if ( approval ) {
          Promise.all([
            loadQuote( quoteAsync, approval.quoteInfo.quoteId, approval.quoteInfo.revision ),
            loadQuoteComments(approval.quoteInfo.quoteId),
            loadQuoteAssemblyExceptions(approval.quoteInfo.quoteId),
            loadPerformance( approval.quoteInfo.quoteId),
            loadPoRequestDetails( poRequestDetailAsync, approval.quoteInfo.quoteRevisionId)
          ]).then( ([q, commentLst]) => {
              setQuoteCommentCnt( commentLst?.length );
              loadCustomOptions( q?.displayRevisionId );
              loadComponentLocations( q?.displayRevisionId );
            });
        }
      });
    return () => loadQuoteApproval.cancel()
  }, [params.approvalId]);


  const loadPoRequestDetails = useCallback(throttle( async (poRequestDetailAsync:AsyncState<DocusignPoRequestDetail>, quoteRevisionId: number | undefined ): Promise<DocusignPoRequestDetail | undefined> => {  
    if (!quoteRevisionId) return;

    if ( cancelLoadPoRequestDetailSourceRef.current ) {
      cancelLoadPoRequestDetailSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelLoadPoRequestDetailSourceRef.current = cancelSource;

    try {
      poRequestDetailAsync.setLoading();
      const resp = await configurator.api.getDocusignRequestDetail(quoteRevisionId, cancelSource.token);
      cancelLoadPoRequestDetailSourceRef.current = undefined;

      poRequestDetailAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          notification.error( { message: "Failed to get po request details. " + errorMsg, duration: 500 });
          poRequestDetailAsync.setFail(errorMsg);
        }
    }
    return;

  }, DEFAULT_THROTTLE), []);

  const loadQuoteApproval = useCallback(throttle( async (quoteAsync:AsyncState<Approval>, approvalId:string | undefined ): Promise<Approval | undefined> => {  
    if (!approvalId) return;

    if ( cancelLoadApprovalTokenSourceRef.current ) {
      cancelLoadApprovalTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelLoadApprovalTokenSourceRef.current = cancelSource;

    try {
      approvalAsync.setLoading();
      const resp = await configurator.api.getQuoteApproval(approvalId, cancelSource.token);
      cancelLoadApprovalTokenSourceRef.current = undefined;

      approvalAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
      if (e instanceof NotFoundError) {
        quoteAsync.setFail( "Approval not found." );
      }
      else {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          notification.error( { message: "Failed to get quote approval. " + errorMsg, duration: 500 });
          quoteAsync.setFail(errorMsg);
        }
      }
    }
    return;

  }, DEFAULT_THROTTLE), []);


  const loadQuote = useCallback(throttle( async (quoteAsync:AsyncState<Quote>, quoteId:string, revision:number | undefined) : Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    if ( cancelLoadQuoteTokenSourceRef.current ) {
      cancelLoadQuoteTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelLoadQuoteTokenSourceRef.current = cancelSource;


    try {
      quoteAsync.setLoading();

      const resp = await configurator.api.getQuoteByRevision(quoteId, revision, cancelSource.token);
      cancelLoadQuoteTokenSourceRef.current = undefined;

      quoteAsync.setDone(resp.data);

      return resp.data;
    } catch (e:any) {
      if (e instanceof NotFoundError) {
        quoteAsync.setFail( "Quote not found." );
      }
      else {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          notification.error( { message: "Failed to get quote details. " + errorMsg, duration: 500 });
          quoteAsync.setFail(errorMsg);
        }
      }
    }

    return;
  }, DEFAULT_THROTTLE), []);

  const loadQuoteComments = async (quoteId:string) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.fetchQuoteComments(quoteId, {topic: topics});
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch comments " + errorMsg });
    }
    return;
  }

  const reloadCustomOptions = async () : Promise<CustomOptionType[] | undefined> => {
    return loadCustomOptions( quote?.displayRevisionId );
  }
  const loadCustomOptions = async (quoteRevisionId:number | undefined) : Promise<CustomOptionType[] | undefined> => {
    if ( !quoteRevisionId ) return;

    customOptionLstAsync.setLoading()
    try {
      const resp = await configurator.api.getCustomOptions(quoteRevisionId)
      customOptionLstAsync.setDone(resp.data);

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to fetch custom options. " + errorMsg;
      notification.error( { message: msg });
      customOptionLstAsync.setFail(msg);
    }

    return;
  }

  const loadQuoteAssemblyExceptions = async (quoteId:string | undefined) : Promise<QuoteAssemblyException[] | undefined> => {
    if ( !quoteId ) return;

    quoteAssemblyExceptionLstAsync.setLoading()

    try {
      const resp = await configurator.api.fetchQuoteAssemblyExceptions(quoteId);
      quoteAssemblyExceptionLstAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load assembly exceptions. " + errorMsg });
      quoteAssemblyExceptionLstAsync.setFail(e.message);
    }

    return;
  }

  const loadPerformance = async (quoteId:string | undefined, rev?:number | undefined) : Promise<Performance | undefined> => {
    if ( !quoteId?.length ) return;

    try {
      const resp = await configurator.api.fetchQuotePerformance(quoteId, rev);
      performanceAsync.setDone(resp.data);
      return resp.data;
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get performance statistics. " + errorMsg });
      performanceAsync.setFail( e.message );
    }

    return;
  }
  const loadComponentLocations = async (quoteRevisionId:number | undefined) : Promise<Record<string, string> | undefined> => {
    if ( !quoteRevisionId ) return;

    componentLocationsAsync.setLoading()

    try {
      const resp = await configurator.api.fetchComponentLocations(quoteRevisionId);
      componentLocationsAsync.setDone(resp.data)
      return resp.data;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load component locations. " + errorMsg });
      componentLocationsAsync.setFail(e.message);
    }

    return;
  }

  const handleApprove = (approval:Approval | undefined) => {
    if ( approval?.action ) {
      approvalAsync.setDone(approval);
      setShowApprovalResults(true);
    }
  }
  const handleReject = (approval:Approval | undefined) => {
    if ( approval?.action ) {
      approvalAsync.setDone(approval);
      setShowApprovalResults(true);
    }
  }

  const getSplitOrderWithoutSubmission = (): string[] => {
    if (!approval?.pendingSplit?.self) return [];
    return [
      ...( !!(approval?.pendingSplit?.selfSubmitted) ? [] : [approval?.pendingSplit?.self]),
      ...(approval?.pendingSplit?.partners?.filter(partner => !partner.partnerSubmitted).map(partner => partner.quoteId) || [])
    ];
  }

  const {
    isSalesChangeOrder,
    isEngineeringChangeOrder,
    isChangeOrderSalesDeskPoStep,
    isChangeOrderSalesDeskReviewStep,
  } = Utils.getQuoteState(configurator, quoteAsync, false);

  const isApproverRole = configurator.hasRole( approval?.approverRole ) || configurator.isAdmin();
  const isChangeRequest = isSalesChangeOrder || isEngineeringChangeOrder || approvalAsync?.val?.quoteInfo.revisionType === RevisionType.SPLIT_ORDER;
  const isReleaseEngineeringView = approval && ReleaseEngineeringRoles.includes( approval.approverRole );
  const isApplicationEngineeringView = approval && ApproverRole.ENGINEERING === approval.approverRole;
  const isSalesView = approval && !isReleaseEngineeringView;
  const hasAdminView = configurator.isEngineering() || configurator.isSalesDesk() || configurator.isAdmin();

  const showPoConfirm = isChangeOrderSalesDeskPoStep || isChangeOrderSalesDeskReviewStep;

  const allSplitChangeReady = approval?.pendingSplit == undefined || (approval.pendingSplit?.selfSubmitted && approval.pendingSplit?.partners.every(p => !!p.partnerSubmitted));
  const splitNotSubmitted = getSplitOrderWithoutSubmission();

  const getApproveDisabledMsg = () => {
    return !isApproverRole  ? `This approval requires a role of ${Utils.snakeCaseToFirstLetterCapitalized( approval?.approverRole )}`
      : !allSplitChangeReady ? `Some split orders have not been submitted: ${splitNotSubmitted.join(", ")}`
      : approval?.action  ? `This quote has already been ${approval.action.toLowerCase()}.`
      : undefined;
  }

  const getRejectDisabledMsg = () => {

    return !isApproverRole  ? `This approval requires a role of ${Utils.snakeCaseToFirstLetterCapitalized( approval?.approverRole )}`
      : approval?.action  ? `This quote has already been ${approval.action.toLowerCase()}.`
      : undefined;
  }

  const tabItems:any[] = [];
  if (isChangeRequest) tabItems.push({
    key:"changes",
    label: "Changes",
    children: <ChangeOrderDiff quote={quote} approvalAsync={approvalAsync}/>
  });

  tabItems.push( {
    key:"spec",
    label: "Specifications",
    children: <ConfigurationTabContents />
  });

  return <div className="site-layout-background">
    <Title level={2}>Approval Request - <span style={{textTransform: "uppercase"}}>{Utils.formatApprovalType(approval?.approvalType, approval?.quoteInfo.reservation)}</span></Title>

    <Skeleton active loading={approvalAsync.isLoading()} >
    <QuoteContextProvider value={{ quoteAsync, adminView: hasAdminView }}>
    <Space direction="vertical" style={{width: "100%"}}>

      <PendingSplitAlert approval={approval} />

      <MissingPoAlert approval={approval} poRequestDetail={poRequestDetail} />

      <PoAmountAlert approval={approval} poRequestDetail={poRequestDetail} />

      <div style={{display: "flex", gap: ".5rem 2rem", flexWrap: "wrap"}}>

        <div className={styles["section"]}>
          <div >Requested:</div>
          <div ><span>{approval?.requestedBy?.name}</span></div>
          <div ><span>{dayjs(approval?.createdAt).format("MMMM Do YYYY")}</span></div>
        </div>

      </div>

      {!!approval?.workflow?.length && <ApprovalTransitionInfo
        quote={quote}
        approval={approval}
        isSingleAction={isChangeRequest}
      />}

      <ApprovalResult  
        approval={approval}
        quoteStatus={quote?.status}
        showApprovalResults={showApprovalResults}
        setShowApprovalResults={setShowApprovalResults}
      />

      <Divider />

        <Row gutter={[20, 20]}>
          <Col span={22}>
            <div style={{display: "flex", flexDirection: "row-reverse", gap: "1rem"}} >
              <ApproveButton type="primary"
                showPoConfirm={showPoConfirm}
                data-testid="approve-btn"
                disabled={!!getApproveDisabledMsg()}
                onDisabledClick={() => Utils.notifyDisabled(getApproveDisabledMsg())}
                value={approval}
                onChange={handleApprove} >
                Approve 
              </ApproveButton>
              <RejectButton danger type="primary" 
                data-testid="reject-btn"
                disabled={!!getRejectDisabledMsg()}
                onDisabledClick={() => Utils.notifyDisabled(getRejectDisabledMsg())}
                value={approval} 
                onChange={handleReject} >
                Reject
              </RejectButton>

              <Button type="primary" onClick={() => setShowComments(true)} style={{marginRight: "2rem"}} >
                Comments<Badge count={quoteCommentCnt} size="small" >&nbsp;</Badge>
              </Button>
            </div>
          </Col>
          <Col span={15}>
            <Space direction='vertical' size="middle">
              <QuoteHeader  hidePricing={true} />
              <DetailsTabContents isReleaseView={isReleaseEngineeringView}  /> 
              <Card title="Engineering Review" size="small" >
                <VfdReviewPanel includeAllCustomOptions={false} />
              </Card>
            <Row gutter={[20, 20]}>
              {(performance && !isReleaseEngineeringView) && <Col >
                <PerformanceContents performance={performance} /> 
              </Col> }
              {(componentLocations && isApplicationEngineeringView ) && <Col >
                <ComponentLocationContents componentLocations={componentLocations} /> 
              </Col> }
              </Row>
              {( !!quoteAssemblyExceptionLst?.length && isApplicationEngineeringView) &&
                <Card bodyStyle={{padding: 0}} >
                  <QuoteAssemblyExceptionsReview value={quoteAssemblyExceptionLstAsync} />
                </Card>
              }
              {(!!customOptionLst?.length && !isReleaseEngineeringView) &&
                <Card bodyStyle={{padding: 0}} >
                  <CustomOptionsReview value={customOptionLstAsync} onChange={reloadCustomOptions} />
                </Card>
              }
              {isChangeRequest ? <Tabs items={tabItems} /> : <ConfigurationTabContents /> }
            </Space>
          </Col>
          {isSalesView && 
          <Col style={{maxWidth: "25rem"}} >
            <Space direction='vertical'>
              <Card >
                <PricingTabContents />
              </Card>
            </Space>
          </Col>}
          <CommentsDrawer 
            quote={quote}
            topics={topics}
            open={showComments} 
            onClose={() => setShowComments(false)} />
        </Row>
    </Space>
    </QuoteContextProvider>
    </Skeleton>
  </div>
}

const ChangeOrderDiff = (props:{
  quote:Quote | undefined
  approvalAsync: AsyncState<Approval>
}) => {

  const [approvalDiff, approvalDiffAsync] = useAsyncState<ApprovalDiff>();
  const [partnersDiff, setPartnersDiff] = useState<ApprovalDiff[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const cancelLoadApprovalDiffTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadPartnersDiffTokenSourceRef = useRef<CancelTokenSource>();
  const [modelCategories, modelCategoriesAsync] = useAsyncState<BaseCategory[]>([]);
  const cancelModelCategoryTokenSourceRef = useRef<CancelTokenSource>();
  const quoteId = props.quote?.quoteId;
  const revision = props.quote?.revision;

  const existSplit = !!props.approvalAsync?.val?.pendingSplit;

  const partnersArray = useMemo(
    () =>
      props.approvalAsync?.val?.pendingSplit?.partners?.map((partner) => ({
        quoteId: partner.quoteId,
        revision: partner.revision,
      })),
    [props.approvalAsync?.val?.pendingSplit?.partners]
  );

  const isLoading = approvalDiffAsync.isLoading() || !approvalDiffAsync.val || (existSplit && !partnersArray?.length);
  
  const loadApprovalDiff = useCallback(throttle( async (approvalDiffAsync:AsyncState<ApprovalDiff>, quoteId: string | undefined, revision: number | undefined): Promise<ApprovalDiff | undefined> => {
    if (!quoteId) return;
    if (!revision) return;

    approvalDiffAsync.isLoading();
    try {
      const resp = await Utils.executeWithCancelToken(cancelLoadApprovalDiffTokenSourceRef, (token) => 
        configurator.api.diffRevisions(quoteId, revision, undefined, token)
      );

      approvalDiffAsync.setDone(resp?.data);

      return resp?.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error({ message: "Failed to fetch approval differences. " + errorMsg });
      approvalDiffAsync.setFail(e.message);
    }

    return;
  }, DEFAULT_THROTTLE), []);

  const loadPartnersDiff = useCallback(throttle(
    async (
      originalQuoteId: string,
      originalRevision: number,
      splitQuoteId: string,
      splitRevision: number
    ): Promise<ApprovalDiff | undefined> => {
      try {
        const resp = await Utils.executeWithCancelToken(cancelLoadPartnersDiffTokenSourceRef, (token) => 
          configurator.api.diffArbitraryRevisions(
            splitQuoteId,
            splitRevision,
            originalQuoteId,
            originalRevision,
            token
          )
        );
        return resp?.data;
      } catch (e: any) {
        const errorMsg = intl.formatMessage({ id: e.message });
        notification.error({
          message: "Failed to fetch split order differences. " + errorMsg,
        });
      }
    }, DEFAULT_THROTTLE), []);

  const reloadModelCategories = async () => loadModelCategories(modelCategoriesAsync, props.quote?.model.id);
  const modelCategoryContext = useMemo<ModelCategoryContextType>( () => ({
    modelCategoriesAsync, loadModelCategories:reloadModelCategories
  }) , [ modelCategories ]);

  useEffect(() => {
    if ( !props.quote?.productionDate ) return;

    reloadModelCategories();
  }, [props.quote?.model.id] );


  const loadModelCategories = useCallback(throttle( async ( modelCategoriesAsync:AsyncState<BaseCategory[]>, modelId:number | undefined ) : Promise<BaseCategory[] | undefined> => {
    const id = modelId;
    if (!id) return;

    if ( cancelModelCategoryTokenSourceRef.current ) {
      cancelModelCategoryTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelModelCategoryTokenSourceRef.current = cancelSource;

    try {
      modelCategoriesAsync.setLoading();
      const resp = await configurator.api.getModelCategories(id )
      cancelModelCategoryTokenSourceRef.current = undefined;

      const configuratorCategories = resp.data;

      var categories = configuratorCategories
      .filter(cat => cat.name != "Default")
      .sort((a,b) => a.name.localeCompare(b.name));

      modelCategoriesAsync.setDone( categories );
      return categories;

    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        modelCategoriesAsync.setFail( errorMsg );
      }
    }

    return;
  }, DEFAULT_THROTTLE), []);

  useEffect(() => {
    loadApprovalDiff( approvalDiffAsync, quoteId, revision ) 
  }, [quoteId, revision]);

  useEffect(() => {
    const originalQuoteId = props.approvalAsync.val?.pendingSplit?.self;
    const originalRevision = props.approvalAsync.val?.pendingSplit?.selfParentRevision;
  
    const loadAllPartnersDiffs = async () => {
      if (partnersArray?.length && originalQuoteId && originalRevision) {
        const partnerDiffPromises = partnersArray.reduce(async (accPromise, partner) => {
          const acc = await accPromise;
          const diff = await loadPartnersDiff(
            originalQuoteId,
            originalRevision,
            partner.quoteId,
            partner.revision
          );
          if (diff) {
            acc.push(diff);
          }
          return acc;
        }, Promise.resolve([] as ApprovalDiff[]));
  
        const allPartnersDiffs = await partnerDiffPromises;
        setPartnersDiff(allPartnersDiffs);
      }
    };
  
    loadAllPartnersDiffs();
  }, [partnersArray, loadPartnersDiff]);



  if ( Utils.isEmptyDeep(approvalDiff) ) {
    return <>There are no changes in this revision.</>
  }

  const hasPartners = !!partnersArray?.length;

  return <ModelCategoryContext.Provider value={modelCategoryContext}>
    <Spin spinning={isLoading}>
      <Space direction='vertical' style={{width: "100%"}}>
        <LeadTimeWarning assemblies={approvalDiff?.assembliesDiff?.addedAssemblies} />
        <LayoutWarning assemblies={approvalDiff?.assembliesDiff?.addedAssemblies} />

        {!!props.quote?.changeSummary?.length &&
          <Descriptions layout="vertical" className={styles['quoteQuickView-description']}  column={1}
            items={[
              {
                label: <Title level={5}>Change Summary</Title>,
                children: props.quote?.changeSummary
              }
            ]} 
            size="small" colon={false} 
            style={{padding: 0}}
          />}

        <React.Fragment key={quoteId || 'main-approval-diff'}>
          <ApprovalDiffTable 
            diff={approvalDiff}
            quoteId={hasPartners ? quoteId : undefined}
          />
        </React.Fragment>

        {hasPartners && partnersDiff?.map((partnerDiff, i) => (
          <React.Fragment key={`${partnersArray[i].quoteId}-${i}`}>
            <ApprovalDiffTable 
              diff={partnerDiff}
              quoteId={partnersArray[i].quoteId}
            />
          </React.Fragment>
        ))}
      </Space>
    </Spin>
  </ModelCategoryContext.Provider>

}

const CommentsDrawer = (props:DrawerProps & {
  quote:Quote | undefined
  topics:CommentTopic[]
}) => {

  return <>
    <Drawer
      {...props}
      title={<>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
          <div>{props.quote?.partNumberString} Comment(s)</div>
        </div>
      </>}
    >
      <QuoteCommentList topics={props.topics} />
    </Drawer>
  </>
}


const RejectButton = (props:Omit<BMButtonProps, 'onChange' | 'value'> & {
  value:Approval | undefined
  onChange?: (q:Approval | undefined) => void
}) => {

  const {value:b, onChange:a, ...btnProps } = props;
  const [approval, approvalAsync] = useAsyncState<Approval>();

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();
  const actionNotes = Form.useWatch<string | undefined>('actionNotes', form);

  const handleOpen = (open:boolean) => {
    if (open ) {
      approvalAsync.setDone(props.value);
    }
  }

  const handleReject = async () => {
    const approvalId = approval?.id;
    if (!approvalId ) return;

    const formValues = await form.validateFields();
    const approved = await reject( approvalId, formValues );
    if ( approved ) {
      props.onChange?.(approved);
      setIsOpen(false);
    }

  }

  const reject = async (approvalId:number, values:Record<string, any>) : Promise<Approval | undefined> => {

    try{
      approvalAsync.setLoading();
      const resp = await configurator.api.approvalAction(approvalId, {
        ...values,
        action: ApprovalAction.REJECTED
      })

      approvalAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to reject. " + errorMsg, duration: 500 });
        approvalAsync.setFail(errorMsg);
      }
    }

    return;

  }

  return <>
    <BMButton
      onClick={() => setIsOpen(true)}
      {...btnProps}
    >
      Reject
    </BMButton>
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      afterOpenChange={handleOpen}
      footer={[
        <Button key="cancel" 
          onClick={() => setIsOpen(false)} >
          Cancel
        </Button>,  
        <BMButton danger key="reject" type="primary" 
          onClick={handleReject} 
          disabled={!actionNotes?.length}
          onDisabledClick={() => form.validateFields()}
        >
          Reject
        </BMButton>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="actionNotes"
          label="Reject Reason (Public)}"
          rules={[{ required: true, message: "A reason is required." }]}
        >
          <TextArea placeholder={'Please provide a reason.'} rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  </>
}

const ApproveButton = (props:Omit<BMButtonProps, 'onChange' | 'value'> & {
  value:Approval | undefined
  onChange?: (q:Approval | undefined) => void
  showPoConfirm?: boolean | undefined;
}) => {

  const {value:b, onChange:a, showPoConfirm, ...btnProps } = props;
  const [approval, approvalAsync] = useAsyncState<Approval>();

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const {quoteAsync} = useQuoteContext();
  const quote = quoteAsync?.val;

  const pricing = useQuotePricing({
    quoteId: quote?.quoteId,
    revision: quote?.revision
  }).data;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();

  const handleOpen = (open:boolean) => {
    if (open ) {
      approvalAsync.setDone(props.value);
    }
  }

  const handleApprove = async () => {
    const approvalId = approval?.id;
    if (!approvalId ) return;

    const formValues = await form.validateFields();
    const approved = await approve( approvalId, formValues );
    if ( approved ) {
      props.onChange?.(approved);
      setIsOpen(false);
    }

  }

  const approve = async (approvalId:number, values:Record<string, any>) : Promise<Approval | undefined> => {

    try{
      approvalAsync.setLoading();
      const resp = await configurator.api.approvalAction(approvalId, {
        ...values,
        action: ApprovalAction.APPROVED
      })

      approvalAsync.setDone(resp.data);
      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to approve. " + errorMsg, duration: 500 });
        approvalAsync.setFail(errorMsg);
      }
    }

    return;

  }

  const isReleaseEngineeringView = approval && ReleaseEngineeringRoles.includes( approval.approverRole );
  const isSalesView = approval && !isReleaseEngineeringView;
  const isOrderApproval = !!quote?.trucks?.length;
  const hasPoDocuments = !!quote?.poNumber?.documents.length;
  
  const showPoWarnings = (isSalesView && isOrderApproval);
  const missingPo = (isSalesView && isOrderApproval) && !hasPoDocuments;

  const unitPrice = pricing?.dealerPrice || 0;
  const totalPrice = ( quote?.quantity || 0 ) * unitPrice;
  const poAmount = quote?.poNumber?.amount;
  const isPoAmountMatch = poAmount === unitPrice || poAmount === totalPrice;


  return <>
    <BMButton
      onClick={() => setIsOpen(true)}
      {...btnProps}
    >Approve
    </BMButton>
    <ModalWizard
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      afterOpenChange={handleOpen}
      showSteps={false}
      steps={[
        {
          key:"poWarningStep",
          hidden: !(showPoWarnings && missingPo),
          body: (_nav) => <Result status="warning" 
            title={"This order is missing a Purchase Order."}
          />,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={() => nav.nextStep()} >Next</Button>
            <Button key="cancel" onClick={() => setIsOpen(false)} >Cancel</Button>
          </div>,
        },
        {
          key:"poAmountMatchStep",
          hidden: !(showPoWarnings && !isPoAmountMatch),
          body: (_nav) => <Result status="warning" 
            title={"Incorrect Purchase Order amount."}
            subTitle={`A purchase order amount ${Utils.formatMoney(poAmount)} does not match the unit price ${Utils.formatMoney(unitPrice)} or total price ${Utils.formatMoney(totalPrice)}.`}
          />,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={() => nav.nextStep()} >Next</Button>
            <Button key="cancel" onClick={() => setIsOpen(false)} >Cancel</Button>
          </div>,
        },
        {
          key:"reasonStep",
          body: (_nav) => <>
            <Form
              form={form}
              layout="vertical"
            >
              <Form.Item
                name="actionNotes"
                label="Notes (Public, Optional)"
              >
                <TextArea rows={4} />
              </Form.Item>
            </Form>
          </>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <BMButton key="approve" type="primary" loading={approvalAsync.isLoading()} onClick={handleApprove}>Approve</BMButton>
              {(showPoWarnings && (missingPo || !isPoAmountMatch))
              ? <Button key="back" onClick={() => nav.prevStep()} >Back</Button> 
              : <Button key="cancel" onClick={() => setIsOpen(false)} >Cancel</Button> }
            </div>,
        }
        ]}
    >
    </ModalWizard>
  </>
}

const CustomOptionsReview = (props:{
  value: AsyncState<CustomOptionType[]>,
  onChange: (co:CustomOptionType) => void
}) => {

  const configurator = useContext(ConfiguratorContext);

  const { value:customOptionLstAsync } = props;
  const customOptionLst = props.value.val;

  const isReadOnly = !(configurator.isEngineering() || configurator.isAdmin());

  const btnStyle = isReadOnly
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  return <div key="customOptionsReview">
    <style>
      {`
      .dialog-customOptionLst .ant-table-content { padding: 5px; } /* don't clip corners */
      .dialog-customOptionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
      /* add error border to table */
      .ant-form-item-has-error .dialog-customOptionLst .ant-table-content {
      border: solid 1px #ff4d4f;
      border-radius: 15px;
      }
      `}
    </style>
    <Table
      size="small"
      pagination={{
        hideOnSinglePage:true,
        pageSize: 5,
      }}
      className="dialog-customOptionLst"
      columns={ [ 
        {
          title: "Custom Option",
          render: (co:CustomOptionType) =>
            <EditCustomOptionButtonModal 
              type="text" className="ghostBmButton"
              style={{padding:0}}
              onChange={props.onChange}
              disabled={isReadOnly}
              value={co}
              categoryId={co.category?.id}
            >
              <span style={{...btnStyle}}>{co.content}</span>
            </EditCustomOptionButtonModal>
        },
        {
          title: "Category",
          render: (co:CustomOptionType) => Utils.stripSortingPrefix(co.category?.name)
        },
        {
          title: "Price",
          render: (co:CustomOptionType) => <>{Utils.formatMoney(co.price, "")} {co.disableUpcharge ? undefined : <Tooltip title={"This includes a 25% upcharge on custom options."}>+</Tooltip>}</>
        },
        {
          title: "Selected",
          render: (co:CustomOptionType) => co.included ? <CheckOutlined /> : undefined
        },
      ]}
      rowKey="id"
      loading={customOptionLstAsync?.isLoading()}
      dataSource={customOptionLst}
    />
  </div>;
}

const QuoteAssemblyExceptionsReview = (props:{
  value: AsyncState<QuoteAssemblyException[]>,
  onChange?: (co:QuoteAssemblyException) => void
}) => {

  const { value:quoteAssemblyExceptionLstAsync } = props;
  const quoteAssemblyExceptionLst = props.value.val;

  const getAssemblyLabel = (a:AssemblyInfo) : string | undefined => (!!a.label?.length ? a.label : a.bomDescription);

  return <div key="quoteAssemblyExceptionReview">
    <style>
      {`
      .dialog-quoteAssemblyExceptionLst .ant-table-content { padding: 5px; } /* don't clip corners */
      .dialog-quoteAssemblyExceptionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
      /* add error border to table */
      .ant-form-item-has-error .dialog-quoteAssemblyExceptionLst .ant-table-content {
      border: solid 1px #ff4d4f;
      border-radius: 15px;
      }
      `}
    </style>
    <Table
      size="small"
      pagination={{
        hideOnSinglePage:true,
        pageSize: 5,
      }}
      className="dialog-quoteAssemblyExceptionLst"
                columns={
                  [ {
                  title: "Assembly Exception",
                  render: (ae:QuoteAssemblyException) => <>
                    <div style={{fontWeight: 600}}>{getAssemblyLabel(ae.assembly)}, <span style={{whiteSpace:"nowrap"}}>{ae.assembly.bom}</span></div>
                    <div >{ae.reason} by {Utils.formatUsername(ae.createdBy)} on {dayjs(ae.createdAt).format("MM/DD/YYYY")}</div>
                  </>
                },
                {
                  title: "Category",
                  render: (ae:QuoteAssemblyException) => Utils.stripSortingPrefix(ae.assembly.categoryName)
                },
                {
                  title: "Type",
                  render: (ae:QuoteAssemblyException) => ae.type == QuoteAssemblyExceptionType.OBSOLETE ? "Obsolete" 
                  : ae.type == QuoteAssemblyExceptionType.RULE_OVERRIDE ? "Rule Override" 
                  : ae.type
                },
                ]}
      rowKey="id"
      loading={quoteAssemblyExceptionLstAsync?.isLoading()}
      dataSource={quoteAssemblyExceptionLst}
    />
  </div>;
}

const LayoutWarning = (props:{
  assemblies:AssemblyInfo[] | undefined
}) => {

  const { modelCategoriesAsync} = useContext<ModelCategoryContextType>(ModelCategoryContext);

  const hasCategoryLayout = props.assemblies?.map( asm => asm.category )
  .map( cat => modelCategoriesAsync?.val?.find( c => c.id === cat?.id ) )
  .some( cat => cat?.tags?.some( t => t === CategoryTags.LayoutReview ) );

  if ( !hasCategoryLayout ) return <></>;

  return <Alert type="error" message={`Dependent categories have changed.  Layout needs to be updated!`} />
}

const LeadTimeWarning = (props:{
  assemblies:AssemblyInfo[] | undefined
}) => {

  const { modelCategoriesAsync} = useContext<ModelCategoryContextType>(ModelCategoryContext);

  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;


  const changeCategories = new Set(props.assemblies?.map(a => a.category?.id ) || [] );
  const maxLeadTime = modelCategoriesAsync?.val?.filter( c => changeCategories.has(c.id) )
    .map( m => m.leadTimeDays )
    .reduce( (acc, v ) => v > acc ? v : acc, 0 ) || 0;
  const daysTillProduction = quote?.productionDate ? dayjs(quote.productionDate).diff(dayjs(), 'day') : 0;

  if ( !quote?.productionDate || maxLeadTime < daysTillProduction ) return <></>;

  return <Alert type="error" message={`${daysTillProduction} days till production. Required Lead Time is ${maxLeadTime} days.`} />
}

const PendingSplitAlert = (props:{
  approval:Approval | undefined
}) => {
  const { approval } = props;

  if ( !approval?.pendingSplit ) return <></>;

  return <Alert message={
    <span>
      This approval is for split change. It related to quote 
      <Link style={{marginLeft:"5px", marginRight: "5px"}} to={"/configurator/" + encodeURI(approval.pendingSplit.self)}>{approval.pendingSplit.self}</Link> 
      {approval.pendingSplit.partners.map(partner => (
        <React.Fragment key={partner.quoteId}>
          <span>and</span>
          <Link style={{ marginLeft: "5px", marginRight: "5px" }} to={"/configurator/" + encodeURI(partner.quoteId)}>
            {partner.quoteId}
          </Link>
        </React.Fragment>
      ))}
    </span>}
  />
}

const PoAmountAlert = (props:{
  approval:Approval | undefined
  poRequestDetail: DocusignPoRequestDetail | undefined
}) => {

  const { approval, poRequestDetail } = props;

  const {quoteAsync} = useQuoteContext();
  const quote = quoteAsync?.val;

  const pricing = useQuotePricing({
    quoteId: quote?.quoteId,
    revision: quote?.revision
  }).data;

  //don't bother if there isn't a PO
  const hasPoDocuments = !!quote?.poNumber?.documents;
  if ( !hasPoDocuments ) return <></>;

  if ( !approval ) return <></>;
  if ( !pricing ) return <></>;

  const isOrderApproval = !!quote?.trucks?.length;
  if ( !isOrderApproval ) return <></>;

  const unitPrice = pricing?.dealerPrice;
  const totalPrice = ( quote?.quantity || 0 ) * unitPrice;
  const poAmount = quote?.poNumber?.amount;
  const isPoAmountMatch = poAmount === unitPrice || poAmount === totalPrice;

  if (!isPoAmountMatch ) {
    return <Alert 
      type="warning"
      message={<>
        <div>
        A purchase order amount {Utils.formatMoney(poAmount)} does not match the unit price {Utils.formatMoney(unitPrice)} or total price {Utils.formatMoney(totalPrice)}. 
        </div>
        {poRequestDetail?.requestedAt && <div style={{marginTop: "1rem"}}>
          A purchase order was requested on {dayjs(poRequestDetail?.requestedAt).format("MMM Do YY, HH:mm") } but has not been completed.
        </div>}
      </>}
    />
  }

  return <></>;
}

const MissingPoAlert = (props:{
  approval:Approval | undefined
  poRequestDetail: DocusignPoRequestDetail | undefined
}) => {
  const { approval, poRequestDetail } = props;

  const configurator = useContext(ConfiguratorContext);
  const {quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;
  const isDocusignEnabled = configurator.hasFeature(FEATURE_DOCUSIGN);

  const isReleaseEngineeringView = approval && ReleaseEngineeringRoles.includes( approval.approverRole );
  const isSalesView = approval && !isReleaseEngineeringView;
  const isOrderApproval = !!quote?.trucks?.length;

  const showPoWarnings = (isSalesView && isOrderApproval);
  if ( !showPoWarnings ) return <></>;

  const hasPoDocuments = !!quote?.poNumber?.documents.length;
  if ( hasPoDocuments ) return <></>;

  if ( !poRequestDetail?.requestedAt ) {
    return <Alert 
      type="warning"
      message={<>
        This order is missing a Purchase Order. 
        {isDocusignEnabled &&
        <RequestPoModalButton type="text"><span style={{textDecoration: "underline"}}>Request Purchase Order</span></RequestPoModalButton> }
      </>}
    />
  }
  
  return <Alert 
    type="warning"
    message={<>
      A purchase order was requested on {dayjs(poRequestDetail?.requestedAt).format("MMM Do YY, HH:mm") } but has not been completed.
    </>}
  />
 
}

export default ApprovalDetailPage;

